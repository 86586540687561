import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

const baseUrl = `${environment.apiUrl}/role`;
const baseUrlRoleAccess = `${environment.apiUrl}/RoleAccess`;
@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(private http: HttpClient) {}

  getRoleList(companyId: any) {
    return this.http.get<any>(`${baseUrl}/roleGetAll/${companyId}`);
  }

  getRoleIdividual(companyId: any) {
    return this.http.get<any>(`${baseUrl}/roleGet/${companyId}`);
  }

  addRole(obj: any) {
    return this.http.post<any>(`${baseUrl}/roleCreate`, obj);
  }
  deleteRole(id: any) {
    return this.http.put<any>(`${baseUrl}/roleDelete/${id}`, null);
  }
  roleCreateQuickConfigure() {
    return this.http.get<any>(`${baseUrl}/roleCreateQuickConfigure`);
  }
  updateRole(obj: any, id: any) {
    return this.http.put<any>(`${baseUrl}/roleUpdate/${id}`, obj);
  }

  getModules() {
    return this.http.get<any>(`${baseUrlRoleAccess}/getAllModules`);
  }

  getAccessCodesByModules(obj: any) {
    return this.http.post<any>(
      `${baseUrlRoleAccess}/getAccessCodesByModule`,
      obj
    );
  }

  addAccessCodes(obj: any) {
    return this.http.post<any>(`${baseUrlRoleAccess}/insertRoleAccess`, obj);
  }

  updateAccessCodes(obj: any) {
    return this.http.put<any>(`${baseUrlRoleAccess}/updateRoleAccess`, obj);
  }

  getAccessCodes() {
    // let suburl =roleId == null? "/GetRoleAccess": `/GetRoleAccess/${roleId}`;
    return this.http.get<any>(
      `${baseUrlRoleAccess}/GetRoleAccess`
    );
  }
  getAccessCodesByRoleId(roleId: any) {
    // let suburl =roleId == null? "/GetRoleAccess": `/GetRoleAccess/${roleId}`;
    return this.http.get<any>(
      `${baseUrlRoleAccess}/GetRoleAccessBasedRoleId/${roleId}`
    );
  }
}
