import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const baseUrl = `${environment.apiUrl}/storage`;
const baseUrlAisle = `${environment.apiUrl}/Aisle`;
const baseUrlBay = `${environment.apiUrl}/Bay`;

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private http: HttpClient) {}

  getAllStorage(id: string) {
    return this.http.get<any[]>(`${baseUrl}/getAllStorage/${id}`);
  }
  getAllStorageNew(obj: any) {
    return this.http.post(`${baseUrl}/getAllStorageNew`,obj);
  }
  getStorage(id: string, companyId: string, code: any) {
    return this.http.get<any[]>(
      `${baseUrl}/getStorage/${id}/${companyId}/${code}`
    );
  }


  insertStorage(data: any) {
    return this.http.post<any[]>(`${baseUrl}/insertStorage`, data);
  }
  updateStorage(id: string, data: any) {
    return this.http.put<any[]>(`${baseUrl}/updateStorage/${id}`, data);
  }
  deleteStorage(id: string) {
    return this.http.put<any[]>(`${baseUrl}/deleteStorage/${id}`, null);
  }

  //aisle

  aisleGetAll(storageId: any, code: any) {
    return this.http.get<any>(
      `${baseUrlAisle}/aisleGetAll/${storageId}/${code}`
    );
  }

  aisleCreate(data: any) {
    return this.http.post<any>(`${baseUrlAisle}/aisleCreate`, data);
  }
  aisleUpdate(id: string, data: any) {
    return this.http.put<any>(`${baseUrlAisle}/aisleUpdate/${id}`, data);
  }
  aisleDelete(id: string) {
    return this.http.put<any>(`${baseUrlAisle}/aisleDelete/${id}`, null);
  }

  bayGetAll(storageId: any, aisleCode: any) {
    return this.http.get<any>(
      `${baseUrlBay}/bayGetAll/${storageId}/${aisleCode}`
    );
  }

  bayCreate(data: any) {
    return this.http.post<any>(`${baseUrlBay}/bayCreate`, data);
  }
  bayUpdate(id: string, data: any) {
    return this.http.put<any>(`${baseUrlBay}/bayUpdate/${id}`, data);
  }
  bayDelete(id: string) {
    return this.http.put<any>(`${baseUrlBay}/bayDelete/${id}`, null);
  }

  getAllStorageByAisleBay() {
    return this.http.get<any>(`${baseUrl}/getAllStorageByAisleBay`);
  }

  //   http://localhost:4000/Aisle/aisleGetAll - Get
  // http://localhost:4000/Aisle/aisleCreate - Post
  // http://localhost:4000/Aisle/aisleUpdate/{aisleId} - PUT
  // http://localhost:4000/Aisle/aisleDelete/{aisleId} - Delete
  // http://localhost:4000/Aisle/aisleGetById/{aisleId} - Get

  // http://localhost:4000/Bay/bayGetAll/ {aisleId}
  // http://localhost:4000/Bay/bayCreate - Post
  // http://localhost:4000/Bay/bayUpdate/{bayId} - PUT
  // http://localhost:4000/Bay/bayDelete/{bayId} - Delete
  // http://localhost:4000/Bay/bayGetById/{bayId} - Get
}
