import { Component } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent {
  menuData: any = {
    width: 0,
    collapsed: false,
  };
  onToggleSideNav(data: any): void {
    // console.log(data);
    this.menuData.width = data.screenWidth;
    this.menuData.collapsed = data.collapsed;
  }
  ngOnInit(){
  }
  getBodyClass(): string {
    let styleClass = '';
    if (this.menuData.collapsed && this.menuData.width > 768) {
      styleClass = 'body-trimmed';
    } else if (
      this.menuData.collapsed &&
      this.menuData.width <= 768 &&
      this.menuData.width > 0
    ) {
      styleClass = 'body-md-screen';
    }
    return styleClass;
  }
}
