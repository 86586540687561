import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { AuditLineItemsService } from '../../../services/audit-line-items.service';
import { ConfirmationService } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { AuditorSummaryComponent } from '../auditor-summary/auditor-summary.component';

@Component({
  selector: 'app-audit-stock-list',
  templateUrl: './audit-stock-list.component.html',
  styleUrls: ['./audit-stock-list.component.css'],
  providers: [ConfirmationService],
})
export class AuditStockListComponent {

  IsUnit! : Boolean;
  IsSerialized! : Boolean;
  IsBatch! : Boolean;
  UniqueIdCount! : number;
  Id1Name! : string;
  Id2Name! : string;
  Id3Name! : string;
  expandedRowIndex: number | null = null;
  IsDamageQty !: Boolean;
  localData: any;
  IsAuditor! : boolean;
  searchKeyword: string = '';

  @Input() parentData: any;
  @Output() childButtonClick = new EventEmitter<any>();
  
  @ViewChild('dt1') dt1: Table | undefined;
  @ViewChild(AuditorSummaryComponent, { static: false })
  auditorSummaryComponent!: AuditorSummaryComponent;
  position: any;
  isAuditorSummary: boolean = false;

  constructor(
    private auditLineItemsService: AuditLineItemsService,
    private confirmationService: ConfirmationService, 
    private toastr: ToastrService,

  ) {}
  ngOnInit(): void {
    this.localData = localStorage.getItem('credentials');
    this.localData = JSON.parse(this.localData);
    console.log(this.parentData, 'this.parentDate in stock list');

    setTimeout(() => {
      this.IsUnit = this.parentData.auditObj.isUnit;
      this.IsSerialized = this.parentData.auditObj.isSerialized;
      this.IsBatch = this.parentData.auditObj.isBatch;
      this.UniqueIdCount = this.parentData.auditObj.uniqueIdCount;
      this.Id1Name = this.parentData.auditObj.id1Name;
      this.Id2Name = this.parentData.auditObj.id2Name;
      this.Id3Name = this.parentData.auditObj.id3Name;
      this.IsDamageQty = this.parentData.auditObj.isDamageQty;
      this.checkAuditor();
    }, 1500);
  }
  checkAuditor(){
    if(this.parentData.IsAuditor)
      this.IsAuditor = this.parentData.IsAuditor;
  }
 
  showViewSummery(position: string) {
    this.auditorSummaryComponent.ngOnInit();
    this.position = position;
    this.isAuditorSummary = true;
  }

  clear(table: Table) {
    console.log(table, 'clear()');
    table.clear();
    this.searchKeyword = '';
  }
  applyFilterGlobal($event: Event, stringVal: string) {
    this.dt1?.filterGlobal(
      ($event.target as HTMLInputElement).value,
      stringVal
    );
  }
  refresh(table : Table){
    table.clear();
    this.childButtonClick.emit("refresh");
    this.searchKeyword = '';
  }
  deleteItem(auditLineItemsId : string, id : string) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Confirmation',
      icon: 'bi bi-exclamation',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        this.confirmDelete(auditLineItemsId, id);
      },
      reject: () => {},
    });
  }
  confirmDelete(auditLineItemsId : string, id : string) {
    let type = 'S';
    this.auditLineItemsService.deleteAuditLineItemHistory(auditLineItemsId, id, type, this.localData.companyId).subscribe(() => {
      this.showSuccess('Deleted');
      // this.refresh();
    });
  }
  showSuccess(msg : string) {
    this.toastr.success(msg  + ' Succussfully', '', {
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
    });
  }
  toggleRowExpansion(index: number) {
    this.expandedRowIndex = this.expandedRowIndex === index ? null : index;
  }

}
