import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IItemAdd } from '../home/items/items';

const baseUrl = `${environment.apiUrl}/items`;
const baseUrlGroup = `${environment.apiUrl}/ItemsGroup`;
@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  constructor(private http: HttpClient) {}

  getAllItems(data: any) {
    return this.http.post(`${baseUrl}/itemsGetAll`, data);
  }
  getAllItemsData(data: any) {
    return this.http.post(`${baseUrl}/itemsGetAllData`, data);
  }
  getItems(id: string) {
    return this.http.get<any[]>(`${baseUrl}/itemsGet/${id}`);
  }
  insertItems(data: IItemAdd) {
    return this.http.post<any[]>(`${baseUrl}/itemsCreate`, data);
  }
  updateItems(id: string, data: IItemAdd) {
    return this.http.put<any[]>(`${baseUrl}/itemsUpdate/${id}`, data);
  }
  deleteItems(id: string) {
    return this.http.put<any[]>(`${baseUrl}/itemsDelete/${id}`, null);
  }
  ImportItemValidation(id: string, data: any) {
    return this.http.post<any[]>(`${baseUrl}/importItemValidation/${id}`, data);
  }
  bulkInsertItems(headers: HttpHeaders, data: any) {
    return this.http.post<any[]>(`${baseUrl}/bulkInsertItems`, data, {
      headers,
    });
  }
  itemsGroupGetAll(id: string) {
    return this.http.get<any>(`${baseUrlGroup}/itemsGroupGetAll/${id}`);
  }

  itemsGroupGetById(id: string) {
    return this.http.get<any>(`${baseUrlGroup}/itemsGroupGet/${id}`);
  }

  itemsGroupCreate(obj: any) {
    return this.http.post<any>(`${baseUrlGroup}/itemsGroupCreate`, obj);
  }
  itemsGroupUpdate(id: any, obj: any) {
    return this.http.put<any>(`${baseUrlGroup}/itemsGroupUpdate/${id}`, obj);
  }
  itemsGroupDelete(id: any) {
    return this.http.put<any>(`${baseUrlGroup}/itemsGroupDelete/${id}`, null);
  }

  getItemsByCategory(categoryIds: string){
    let params = new HttpParams();
    if (categoryIds.length > 0) {
      params = params.set('categoryIds', categoryIds);
    }
    return this.http.get<any[]>(`${baseUrl}/getItemsbyCategory`, { params });
  }
  StorageAisleBayGetAll() {
    return this.http.get(`${baseUrl}/StorageAisleBayGetAll`);
  }
}
