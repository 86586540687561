import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuditLineItemsService } from '../../../services/audit-line-items.service';
import { CrossAuditService } from '../../../services/cross-audit.service';
import { BarcodeStatus } from '../../../services/audit-status.service';
import { AuditService } from '../../../services/audit.service';
import { ActivatedRoute } from '@angular/router';
import { AutoComplete, AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { HidScannerService } from 'src/app/services/hid-scanner.service';
import { StorageService } from 'src/app/services/storage.service';
import { interval, Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-audit-mobile',
  templateUrl: './audit-mobile.component.html',
  styleUrls: ['./audit-mobile.component.css'],
  providers: [ConfirmationService],
})
export class AuditMobileComponent {
  myForm! : FormGroup;
  storageForm! : FormGroup;
  IsItemObj : boolean = false;
  ItemName : any;
  filterItemsList: any[] = [];
  itemList: any[] = [];
  localData : any;
  auditId : any;
  auditPlanId : any;
  screenName : string ="stockAuditMobile"
  maxContainerHeight = '100vh';
  routeSegment : any;
  barcodes : any;
  IsScan : Boolean = false;
  type! : string;
  scanType! : string;
  IsUnit! : Boolean;
  IsSerialized! : Boolean;
  IsBatch! : Boolean;
  barcode! : string;
  countdown: number = 0;
  auditObj: any;
  trigger = false;
  listData : any[] = [];
  IsStop : Boolean = true;
  countdownPausedAt: number | undefined;
  IsEAN13 : Boolean = false;
  barCodeScanObj : any = {};
  overallProgress : number | undefined;
  auditStockListObj : any = {};
  addItemObj : any = {};
  IsDamageQty !: Boolean;
  IsAuditor : boolean = false;

  storageObj: any;
  aisleObj: any;
  bayObj: any;
  fieldName: any = 'storageName';
  storageList: any[] = [];
  aisleList: any[] = [];
  bayList: any[] = [];
  storageItems: any[] = [];
  IsBlink! : Boolean;
  // storageObject : any;

  activeIndex: number = 0;


  private countdownSub!: Subscription;

  @ViewChild('barcodeSuccess') barcodeSuccess!: ElementRef<HTMLAudioElement>;
  @ViewChild('barcodeError') barcodeError!: ElementRef<HTMLAudioElement>;
  @ViewChild('itemCodeAutoComplete') itemCodeAutoComplete: AutoComplete | undefined; // ViewChild reference

  constructor(
    private toastr: ToastrService,
    private auditLineItemsService: AuditLineItemsService,
    private crossAuditService: CrossAuditService,
    private storageService: StorageService,
    private auditService: AuditService,
    private hidScannerService: HidScannerService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,

  ) {}

  ngOnInit(): void {
    this.createForm();
    this.localData = localStorage.getItem('credentials');
    this.localData = JSON.parse(this.localData);
    this.bindStorageValue(this.localData.auditStorageobj);
    if(this.myForm.value.storageObject)
      this.loadStorage();

    this.route.queryParams.subscribe(params => {
      this.auditId = params['id'];
      this.auditPlanId = params['planId'];

      this.scanType = params['type'];
      let type = params['itype'];
      this.type = type;
      if(type == "U")
        this.IsUnit = true;
      else if(type == "S")
        this.IsSerialized = true;
      else if(type == "B")
        this.IsBatch = true;

      this.barCodeScanObj.batchNo = '';
      this.barCodeScanObj.id1Name = '';
      this.barCodeScanObj.id2Name = '';
      this.barCodeScanObj.id3Name = '';

      this.getAllAuditedLineItems();
      this.getAllStorage();
      let parentData : any = {};
      parentData.localData = this.localData;
      this.addItemObj = parentData;
      this.addItemObj.showPopup = false;
    });
    this.getScreenName();
    this.getAllItemsByCodeNameEan();
    this.getAudit();
    // this.focusItem();

    const windowHeight = window.innerHeight;
    this.maxContainerHeight = `${windowHeight}px`;

    if(this.scanType == 'BarcodeScan'){
      this.hidScannerService.getScans().subscribe((data) => {
        this.barcode = data;
        this.loadBarcodeScan(this.barcode);
      });
    }
    else if(this.scanType == 'CameraScan'){
      this.triggerChildFunction();
    }

  }
  getAllStorage() {
    this.storageService
      .getAllStorage(this.localData.companyId)
      .subscribe((data: any) => {
        this.storageList = data;
      });
  }

  getCrossAuditDetails(){
    let branchId : any = sessionStorage.getItem('branchId');

    this.crossAuditService.getCrossAuditDetails(this.auditId, this.localData.companyId, branchId, this.auditPlanId).subscribe((data: any) => {
      if(data){
        this.overallProgress = data.overallProgress;
      }
    });
  }
  loadBarcodeScan(code : any){
    console.log("this.barcode objData", code)
    this.barCodeScanObj = {};
    if(!this.IsEAN13){
      let splitKey = this.auditObj.barcodeDetails.split(this.auditObj.barcodeSplitter);
      let splitValue = code.split(this.auditObj.barcodeSplitter);
      let data: { [key: string]: string } = {};
      if(splitKey.length > 0){
        for(let i = 0; i < splitKey.length; i++){
          let key = splitKey[i].trim();
          let value = splitValue[i] ? splitValue[i].trim() : '';
          console.log("splitKey", key);
          data[key] = value;
        }
        this.barCodeScanObj.itemCode = data["Item Code"];
        if(this.IsBatch)
          this.barCodeScanObj.batchNo = data["Batch No"];
        else if(this.IsSerialized){
          if(this.auditObj.uniqueIdCount >= 1){
            this.barCodeScanObj.id1Name = data[this.auditObj.id1Name];
          }
          if(this.auditObj.uniqueIdCount >= 2){
            this.barCodeScanObj.id2Name = data[this.auditObj.id2Name];
          }
          if(this.auditObj.uniqueIdCount >= 3){
            this.barCodeScanObj.id3Name = data[this.auditObj.id3Name];
          }
        }
      }
      console.log("split", data)
    }else{
      this.barCodeScanObj.itemCode = code;
    }
    console.log("objData", this.barCodeScanObj)

    this.filterItems(this.barCodeScanObj, 'barcodeScan');
  }
  getAllAuditedLineItems(){
    this.auditLineItemsService.getAllAuditedLineItems(this.auditId, this.localData.companyId).subscribe((data: any) => {
      this.listData = data;
      this.loadData();
      this.auditStockListObj.listData = this.listData;
    });
  }
  loadData(){
    if(this.listData && this.listData.length > 0){
      for (let i = 0; i < this.listData.length; i++) {
        if(this.listData[i].stockAuditHistory != null)
          this.listData[i].stockAuditHistory = JSON.parse(this.listData[i].stockAuditHistory);
        else
          this.listData[i].stockAuditHistory = [];
      }
    }
  }
  focusItem() {
    setTimeout(() => {
      if (this.itemCodeAutoComplete && this.itemCodeAutoComplete.inputEL && this.itemCodeAutoComplete.inputEL.nativeElement) {
        this.itemCodeAutoComplete.inputEL.nativeElement.focus(); // Focus the input element inside p-autoComplete
      }
    }, 0);
  }
  getScreenName(){
    const fullUrl = window.location.href;
    const hashIndex = fullUrl.indexOf('#');
    if (hashIndex !== -1) {
      const hashFragment = fullUrl.substring(hashIndex + 1);
      const routeSegmentEndIndex = hashFragment.indexOf('?');
      if (routeSegmentEndIndex !== -1) {
        this.routeSegment = hashFragment.substring(1, routeSegmentEndIndex);
      } else {
        this.routeSegment = hashFragment.substring(1);
      }
    }
    if(this.routeSegment == 'crossAudit'){
      this.getCrossAuditDetails();
    }
  }
  refresh(){
    this.getAllAuditedLineItems();
  }
  createForm() {
    this.myForm = new FormGroup({
      auditLineItemsId: new FormControl(),
      itemCode: new FormControl(null, [Validators.required]),
      auditedQty: new FormControl(1),
      damagedQty: new FormControl(0),
      stockLocation: new FormControl(''),
      auditStockLocation: new FormControl(''),
      id1Name: new FormControl(''),
      id2Name: new FormControl(''),
      id3Name: new FormControl(''),
      batchNo: new FormControl(''),
      storageObject: new FormControl(''),
    });
  }
  getAudit(){
    let branchId : any = localStorage.getItem('branchId');
    this.auditService.getAudit(this.auditId, this.localData.companyId, branchId).subscribe((data: any) => {
      this.auditObj = data;
      this.checkAuditor();
      this.IsDamageQty = this.auditObj.isDamageQty ? this.auditObj.isDamageQty : false;
      this.auditStockListObj.auditObj = this.auditObj;
      if(this.auditObj.barcodeType && this.auditObj.barcodeType == BarcodeStatus.Ean)
        this.IsEAN13 = true;

      //sampe testing data
      // setTimeout(() => {
      //   let test = "23121610063934@ME3P7C6FFR10002861@P7A6FFR1241939@nvc";
      //   this.loadBarcodeScan(test);
      // }, 3000);

    });
  }
  checkAuditor(){
    if(this.auditObj.auditors){
      let split = this.auditObj.auditors.split(",");
      if (split.includes(this.localData.userId))
        this.IsAuditor = true;

      this.auditStockListObj.IsAuditor = this.IsAuditor;
    }
  }
  loadType(data : any){
    if(data.isUnit)
      this.type = 'U';
    else if(data.isSerialized)
      this.type = 'S';
    else if(data.isBatch)
      this.type = 'B';
  }
  getAllItemsByCodeNameEan(){
    let data : any = {};
    data.CompanyId = this.localData.companyId;
    data.AuditId = this.auditId;
    data.AuditPlanId = this.auditPlanId;
    data.InventoryType = this.type;
    data.ItemCode = "";
    data.auditType = 'S';
    data.userId = this.localData.userId;
    let branchId : any = localStorage.getItem('branchId');
    data.branchId = branchId;
    this.auditLineItemsService.getAllItemsByCodeNameEan(data).subscribe((data) => {
      this.itemList = data;
    });
  }
  addQty(screen : string, operation : string){
    if(screen == "audited"){
      const currentQty = this.myForm.value.auditedQty;
      if(operation == "minus"){
        this.myForm.patchValue({
          auditedQty: currentQty - 1,
        });
      }else{
        this.myForm.patchValue({
          auditedQty: currentQty + 1,
        });
      }
    }else{
      const currentQty = this.myForm.value.damagedQty;
      if(operation == "minus"){
        this.myForm.patchValue({
          damagedQty: currentQty - 1,
        });
      }else{
        this.myForm.patchValue({
          damagedQty: currentQty + 1,
        });
      }
    }
  }
  scan(){
    this.IsScan = true;
    let data : any = {};
    data.query = this.barcodes;
    if(this.barcodes && this.barcodes != null && this.barcodes != ""){
      this.filterItems(data, 'scan');
    }
  }
  add(){
    if(this.myForm.value.itemCode){
      console.log("this.myForm.value", this.myForm.value)
      if (this.myForm.valid) {
        if(!this.IsItemObj && this.myForm.value.itemCode.itemCode){
          let data : any = {};
          data = this.myForm.value;
          data.InventoryType = this.type;
          data.CompanyId = this.localData.companyId;
          data.CreatedBy = this.localData.userId;
          data.UpdatedBy = this.localData.userId;
          data.AuditPlanId = this.auditPlanId;
          data.AuditId = this.auditId;
          if(data.damagedQty != null && data.damagedQty != ''){
            data.damagedQty = parseInt(data.damagedQty);
          }
          data.itemCode = data.itemCode.itemCode;

          data.isActive = true;
          data.createdDate = new Date();
          data.updatedDate = new Date();
          data.auditLineItemsId = "00000000-0000-0000-0000-000000000000";

          if(this.routeSegment == "stockAudit"){
            this.saveStockAudit(data);
          }
          else if(this.routeSegment == "crossAudit"){
            this.saveCrossAudit(data);
          }
        }else{
          if(this.routeSegment == "stockAudit"){
            this.addItemObj.showPopup = true;
            this.addItemObj.itemCode = this.myForm.value.itemCode;
          }
        }
      }
    }
  }
  saveStockAudit(data : any){
    data.auditStockQty = 0;
    let branchId : any = localStorage.getItem('branchId');
    data.branchId = branchId;
    this.auditLineItemsService.insertAuditLineItems(data).subscribe({
      next: (res) => {
        let returnValue = res.toString();
        this.showToasterMsg(returnValue);
        this.apiSuccess();
        this.getAllAuditedLineItems();

        if(!this.myForm.value.storageObject)
          this.showWarning("Storage is not selected.")
      },
      error: error => {
        console.log('Form submission error', error);
      }
    });
  }
  saveCrossAudit(data : any){
    data.auditDamagedQty = data.damagedQty;
    data.auditStockQty = data.auditedQty;
    data.auditedQty = 0;
    data.isActive = true;
    data.isExist = true;
    let branchId : any = localStorage.getItem('branchId');
    data.branchId = branchId;
    this.crossAuditService.saveCrossAudit(data).subscribe({
      next: (res) => {
        this.getCrossAuditDetails();
        let returnValue = res.toString();
        this.showToasterMsg(returnValue);
        this.apiSuccess();
      },
      error: error => {
        console.log('Form submission error', error);
      }
    });
  }
  showToasterMsg(returnValue : any){
    if(returnValue == "M")
      this.showSuccess("Updated Successfully");
    else if(returnValue == "A")
      this.showSuccess("Saved Successfully");
    else if(returnValue == "E")
      this.showError("Item has already been added.");
    else if(returnValue == "D")
      this.showError("Item already exists in the previous audit.");
  }
  apiSuccess(){
    // this.showSuccess("Saved Successfully");
    this.IsStop = true;
    this.clearData();
  }
  clearData(){
    this.myForm.patchValue({
      itemCode: null,
      auditedQty: 1,
      damagedQty: 0,
      stockLocation: '',
      auditStockLocation: '',
      id1Name: '',
      id2Name: '',
      id3Name: '',
      batchNo: '',
    });
    this.countdown = 0;
    this.IsStop = true;
    this.IsBlink = false;
  }
  showWarning(msg : string) {
    this.toastr.warning(msg, '', {
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
    });
  }
  filterItems(event: any, action : string) {
    let filtered: any[] = [];
    let query = null;
    if(event.query)
      query = event.query.toLowerCase();
    else if(event.itemCode)
      query = event.itemCode.toLowerCase();

    let uniqueItemIds = new Set<string>();
    for (let i = 0; i < this.itemList.length; i++) {
      let row = this.itemList[i];
      if (
          (row.itemCode && row.itemCode.toLowerCase().indexOf(query) === 0) ||
          (row.itemName && row.itemName.toLowerCase().indexOf(query) === 0) ||
          (row.eanQr && row.eanQr.toLowerCase().indexOf(query) === 0)
      ) {
          if (!uniqueItemIds.has(row.itemId)) {
            uniqueItemIds.add(row.itemId);
            filtered.push(row);
          }
      }
    }
    this.filterItemsList = filtered;
    // if(action == "type" && this.filterItemsList.length == 1){
    //   setTimeout(() => {
    //     if (this.itemCodeAutoComplete && this.itemCodeAutoComplete.overlayVisible) {
    //       this.itemCodeAutoComplete.hide();
    //     }
    //   }, 1000);
    // }
    if(action == "scan" || action == "barcodeScan"){
      this.barcodes = null;
      this.IsScan = false;

      if(this.filterItemsList && this.filterItemsList.length > 0){
        this.myForm.patchValue({
          itemCode: this.filterItemsList[0],
        });
        this.onItemSelect();

        if(this.IsBatch){
          if(this.filterItemsList[0].batchNoList && this.filterItemsList[0].batchNoList.length > 0){
            let matchedBatchNo = this.filterItemsList[0].batchNoList.find((t: { name: any; }) => t.name === this.barCodeScanObj.batchNo);
            if (matchedBatchNo && matchedBatchNo.name) {
              this.myForm.value.batchNo = this.barCodeScanObj.batchNo;
              this.barcodeScanAddItem(action);
            }
            else
              this.notFound(action, false);
          }
          else
            this.notFound(action, false);
        }
        else if(this.IsSerialized){
          if(this.filterItemsList[0].id1NameList && this.filterItemsList[0].id1NameList.length > 0){
            if(this.auditObj.uniqueIdCount >= 1){
              let matched = this.filterItemsList[0].id1NameList.find((t: { name: any; }) => t.name === this.barCodeScanObj.id1Name);
              if (matched && matched.name) {
                this.myForm.value.id1Name = this.barCodeScanObj.id1Name;

                if(this.auditObj.uniqueIdCount == 1)
                  this.barcodeScanAddItem(action);
              }
              else
                this.notFound(action, false);
            }
            if(this.auditObj.uniqueIdCount >= 2){
              let matched = this.filterItemsList[0].id2NameList.find((t: { name: any; }) => t.name === this.barCodeScanObj.id2Name);
              if (matched && matched.name) {
                this.myForm.value.id2Name = this.barCodeScanObj.id2Name;
                if(this.auditObj.uniqueIdCount == 2)
                  this.barcodeScanAddItem(action);
              }
              else
                this.notFound(action, false);
            }
            if(this.auditObj.uniqueIdCount >= 3){
              let matched = this.filterItemsList[0].id3NameList.find((t: { name: any; }) => t.name === this.barCodeScanObj.id3Name);
              if (matched && matched.name) {
                this.myForm.value.id3Name = this.barCodeScanObj.id3Name;
                if(this.auditObj.uniqueIdCount == 3)
                  this.barcodeScanAddItem(action);
              }
              else
                this.notFound(action, false);
            }
          }
          else
            this.notFound(action, true);
        }
        else if(this.IsUnit)
          this.barcodeScanAddItem(action);
      }else{
        this.notFound(action, false);
      }
    }
  }
  notFound(action : any, IsAdd : any){
    if(action == "barcodeScan"){
      this.showInfo("Item not available.")
      if(IsAdd){
        this.addItemObj.showPopup = true;
        if(this.IsEAN13){
          this.addItemObj.eanQr = this.barCodeScanObj.itemCode;
          this.addItemObj.itemCode = this.myForm.value.itemCode;
        }else{
          this.addItemObj.itemCode = this.barCodeScanObj.itemCode;
        }
      }

    }
  }
  barcodeScanAddItem(action : any){
    if(action == "barcodeScan"){
      this.startCountdown(action);
    }
  }
  loadItemCode(){
    this.onItemSelect();
  }
  onItemSelect() {
    if (typeof this.myForm.value.itemCode === 'object') {
      this.IsItemObj = false;
      this.IsBlink = true;
    }else{
      this.IsItemObj = true;
    }
    this.ItemName = this.myForm.value.itemCode ? this.myForm.value.itemCode.itemName : '';
    this.myForm.patchValue({
      auditStockLocation: this.myForm.value.itemCode ? this.myForm.value.itemCode.storage : '',
    });
  }
  showSuccess(msg : string) {
    this.toastr.success(msg, '', {
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
    });
  }
  showError(msg : string) {
    this.toastr.error(msg, '', {
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
    });
  }
  showInfo(msg : string) {
    this.toastr.info(msg, '', {
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
    });
  }
  playSuccessAudio() {
    if (this.barcodeSuccess && this.barcodeSuccess.nativeElement) {
      this.barcodeSuccess.nativeElement.play();
    } else {
      console.error('Success audio element not found');
    }
  }

  playErrorAudio() {
    if (this.barcodeError && this.barcodeError.nativeElement) {
      this.barcodeError.nativeElement.play();
    } else {
      console.error('Error audio element not found');
    }
  }
  handleChildButtonClick(event: any) {
    if(event.barcodes && event.barcodes != "" && event.barcodes != null){
      this.barcodes = event.barcodes;
      this.playSuccessAudio();
    }else{
      this.barcodes = null;
      this.playErrorAudio();
    }
    console.log('Button clicked in child component!', event);
  }
  stop(){
    if(this.countdown != 0){
      this.stopCountdown();
      this.IsStop = false;
    }
  }
  continue(){
    this.startCountdown("barcodeScan");
    this.IsStop = true;
  }
  startCountdown(action : any) {
    const source = interval(1000); // Emit a value every second
    if (this.countdownPausedAt !== undefined && this.countdownPausedAt > 0) {
      this.countdown = this.countdownPausedAt;
    } else {
      this.countdown = 3; // Initial countdown value
    }
    this.countdownSub = source.subscribe(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        if(action == "barcodeScan"){
          this.add();
        }
        this.stopCountdown();
      }
    });
  }
  stopCountdown() {
    if (this.countdownSub) {
      this.countdownSub.unsubscribe();
      this.countdownPausedAt = this.countdown; // Store the current countdown value
    }
  }
  childButtonFromList(event: any): void {
    if(event == "refresh"){
      this.refresh();
    }
  }
  childButtonFromItem(event: any) {
    if(event == "save"){
      this.getAllItemsByCodeNameEan();
    }
  }
  async getStorage(code: any): Promise<any> {
    let storageId = '00000000-0000-0000-0000-000000000000';
    try {
      const data = await this.storageService
        .getStorage(storageId, this.localData.companyId, code)
        .toPromise();
      return data;
    } catch (error) {
      console.error('Error fetching storage data:', error);
      throw error; // Re-throw the error after logging it
    }
  }
  async getAllAisle(storageId: any, code: any): Promise<any[]> {
    try {
       await this.storageService
        .aisleGetAll(storageId, code)
        .subscribe({
          next:(res:any)=>{
            if(res.status){
              this.aisleList = res.data;
            }else{

            }
          },error:(err:any)=>{

          }
        });
      return this.aisleList;
    } catch (error) {
      console.error('Error fetching aisles:', error);
      throw error;
    }
  }
  async getAllBay(storageId: any, aisleCode: any): Promise<any[]> {
    try {
      this.bayList = await this.storageService
        .bayGetAll(storageId, aisleCode)
        .toPromise();
      return this.bayList;
    } catch (error) {
      console.error('Error fetching bays:', error);
      throw error;
    }
  }
  async loadStorage() {
    if (this.myForm.value.storageObject) {
      let split = this.myForm.value.storageObject.split(' ');
      if (split.length > 0) {
        this.storageObj = await this.getStorage(split[0]);
        let delimeter = this.myForm.value.storageObject.split(
          `${this.storageObj.storageSplitter}`
        );
        this.aisleList = await this.getAllAisle(
          this.storageObj.storageId,
          split[0]
        );
        if (delimeter && delimeter.length > 1) {
          this.aisleObj = this.aisleList.find(
            (item: { aisleCode: any }) => item.aisleCode == delimeter[1].trim()
          );
          this.bayList = await this.getAllBay(
            this.storageObj.storageId,
            delimeter[1].trim()
          );
          if (delimeter.length >= 2) {
            this.bayObj = this.bayList.find(
              (item: { bayCode: any }) => item.bayCode == delimeter[2].trim()
            );
          }
        }
      }
    }
  }
  setStorageValue(){
    this.localData.auditStorageobj = this.myForm.value.storageObject;
    localStorage.setItem('credentials', JSON.stringify(this.localData));
  }
  splitString(value : string, spitter : string){
    return value ? value.split(spitter) : '';
  }
  resetStorageValue(){
    if(this.storageObj && this.storageObj.storageSplitter){
      this.localData = localStorage.getItem('credentials');
      this.localData = JSON.parse(this.localData);
      let oldVal = this.localData.auditStorageobj;
      let newVal = this.myForm.value.storageObject;
      console.log( 'oldValue', oldVal)
      console.log( 'newValue', newVal)

      let splitOldVal = this.splitString(oldVal, this.storageObj.storageSplitter);
      let splitNewVal = this.splitString(newVal, this.storageObj.storageSplitter);
      if(splitOldVal.length > 0){
        let index = -1;
        for(let i = 0; i < splitOldVal.length; i++){
          if(splitOldVal[i].trim() != splitNewVal[i].trim()){
            index = i;
            break;
          }
        }
        if(index >= 0){
          let strg = "";
          for(let i = 0; i < index + 1; i++){
            // if(i == 0 )
            //   strg = splitNewVal[i] + this.storageObj.storageSplitter;
            // else if(i != 0 )
            //   strg = strg + splitNewVal[i] + this.storageObj.storageSplitter;
            if(i == 0 )
              strg = splitNewVal[i];
            else if(i != 0 )
              strg = strg + this.storageObj.storageSplitter + splitNewVal[i];
          }
          if(strg != ""){
            this.bindStorageValue(strg);
            // this.loadStorage();
          }
          console.log("strg", strg);
        }
      }

    }
  }
  loadFilterItems(event: any) {
    this.resetStorageValue();
    this.setStorageValue();
    let filtered: any[] = [];
    let query = event.query.toLowerCase();
    let split: any;
    if (this.storageObj && this.storageObj.storageSplitter) {
      split = query.split(`${this.storageObj.storageSplitter}`);
    }
    let IsStorage = false;
    let IsAisle = false;
    let IsBay = false;
    if (split && split.length > 0) {
      if (split.length == 1) {
        IsStorage = true;
        query = split[0].trim();
        this.fieldName = 'storageShortCode';
      } else if (split.length == 2) {
        IsAisle = true;
        query = split[1].trim();
        this.fieldName = 'aisleCode';
      } else if (split.length == 3) {
        IsBay = true;
        query = split[2].trim();
        this.fieldName = 'bayCode';
      }
    } else {
      IsStorage = true;
      this.fieldName = 'storageShortCode';
    }
    if (IsStorage) {
      for (let i = 0; i < this.storageList.length; i++) {
        let row = this.storageList[i];

        if (
          row.storageShortCode &&
          row.storageShortCode.toLowerCase().indexOf(query) === 0
        ) {
          filtered.push(row);
        }
      }
    } else if (IsAisle) {
      if (this.aisleList.length > 0) {
        for (let i = 0; i < this.aisleList.length; i++) {
          let row = this.aisleList[i];
          if (
            row.aisleCode &&
            row.aisleCode.toLowerCase().indexOf(query) === 0
          ) {
            filtered.push(row);
          } else if (query == '') filtered.push(row);
        }
      }
    } else if (IsBay) {
      if (this.bayList.length > 0) {
        for (let i = 0; i < this.bayList.length; i++) {
          let row = this.bayList[i];
          if (row.bayCode && row.bayCode.toLowerCase().indexOf(query) === 0) {
            filtered.push(row);
          } else if (query == '') filtered.push(row);
        }
      }
    }
    this.storageItems = filtered;
  }
  onItemSelectStorage(){
      this.onItemSelectStorage2();
  }
  bindStorageValue(value : string){
    this.myForm.patchValue({
      storageObject: value,
    });
  }
  async onItemSelectStorage2() {
    console.log("Type of storageObject:", this.myForm.value.storageObject);
    if (typeof this.myForm.value.storageObject === 'object') {
      let bind: any;
      let aisles: any = [];
      let bays: any = [];
      if (this.myForm.value.storageObject.storageShortCode) {
        this.storageObj = this.myForm.value.storageObject;
        let code = null;
        aisles = await this.getAllAisle(this.storageObj.storageId, code);

        if (aisles && aisles.length > 0) {
          bind = `${this.storageObj.storageShortCode} ${this.storageObj.storageSplitter} `;
          this.bindStorageValue(bind);
        }
      } else if (this.myForm.value.storageObject.aisleCode) {
        this.aisleObj = this.myForm.value.storageObject;
        bays = await this.getAllBay(
          this.storageObj.storageId,
          this.aisleObj.aisleCode
        );

        if (bays && bays.length > 0) {
          bind = `${this.storageObj.storageShortCode} ${this.storageObj.storageSplitter} ${this.aisleObj.aisleCode} ${this.storageObj.storageSplitter} `;
        } else
          bind = `${this.storageObj.storageShortCode} ${this.storageObj.storageSplitter} ${this.aisleObj.aisleCode}`;

        this.bindStorageValue(bind);
      } else if (this.myForm.value.storageObject.bayCode) {
        this.bayObj = this.myForm.value.storageObject;

        bind = `${this.storageObj.storageShortCode} ${this.storageObj.storageSplitter} ${this.aisleObj.aisleCode} ${this.storageObj.storageSplitter} ${this.bayObj.bayCode} ${this.storageObj.storageSplitter}`;

        this.bindStorageValue(bind);
      }
    }
    this.setStorageValue();
  }
  updateLocation(){
    // this.confirmationService.confirm({
    //   message: 'Are you sure that you want to proceed?',
    //   header: 'Confirmation',
    //   icon: 'bi bi-exclamation',
    //   acceptLabel: 'Update',
    //   rejectLabel: 'Cancel',
    //   accept: () => {
    //     this.confirmUpdateLocation();
    //   },
    //   reject: () => {},
    // });
    this.confirmUpdateLocation();
  }
  confirmUpdateLocation(){
    this.myForm.patchValue({
      auditStockLocation: this.myForm.value.storageObject,
    });
  }
  triggerChildFunction() {
    setTimeout(() => {
      this.trigger = !this.trigger;
    }, 500);
  }
  onTabChange(event: any) {
    if(event == 0)
      this.scanType = "BarcodeScan";
    else if(event == 1){
      this.scanType = "CameraScan";
      this.triggerChildFunction();
    }
    else if(event == 2)
      this.scanType = "Manual";
  }
  
}
