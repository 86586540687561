<div class="user_table_container">
    <div class="subHeader">
        <div class="row">
            <div class="col-md-3">Audited Stock Item</div>
            <div class="col-md-9">
            </div>
        </div>
    </div>
    <p-table
        #dt1
        [value]="parentData.listData"
        [tableStyle]="{ 'min-width': '60rem' }"
        [paginator]="true"
        [rows]="5"
        [showCurrentPageReport]="true"
        [tableStyle]="{ 'min-width': '50rem' }"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[5, 10, 25, 50]"
        [scrollable]="true"
        scrollHeight="400px"
        [globalFilterFields]="['itemCode', 'itemName', 'auditedQty', 'damagedQty', 'id1Name', 'id2Name', 'id3Name', 'batchNo' , 'location']"
    >
        <ng-template pTemplate="caption">
            <div>
                <span class="p-input-icon-right ml-auto">
                    <span type="button" (click)="refresh(dt1)" class="btn btn-primary btnclr">
                        <i class="bi bi-arrow-clockwise"></i>&nbsp;&nbsp;&nbsp;Refresh
                    </span>
                </span>
                <span class="p-input-icon-right ml-auto ml-2">
                    <i class="bi bi-x-lg" (click)="clear(dt1)"></i>
                    <input
                        pInputText
                        type="text"
                        [(ngModel)]="searchKeyword"
                        (input)="applyFilterGlobal($event, 'contains')"
                        placeholder="Search keyword"
                    />
                </span>
                <span class="viewSummaryBtn">
                    <button type="button" style="border-radius: 6px;" (click)="showViewSummery('right')"
                         class="btn btn-primary btnclr">View Summary</button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th></th>
                <th style="width: 10%">
                    S.No
                </th>
                <th pSortableColumn="itemCode" style="width: 20%">
                    Item Code <p-sortIcon field="itemCode"></p-sortIcon>
                </th>
                <th pSortableColumn="itemName" style="width: 20%">
                    Item Name <p-sortIcon field="itemName"></p-sortIcon>
                </th>
                <th pSortableColumn="userFullName" style="width: 20%;">
                    Auditor Name<p-sortIcon field="userFullName"></p-sortIcon>   
                </th>
                <th pSortableColumn="batchNo" style="width: 20%" *ngIf="IsBatch">
                    Batch No 
                </th>
                <th pSortableColumn="auditedQty" style="width: 15%" *ngIf="IsUnit || IsBatch">
                    Audited Qty <p-sortIcon field="auditedQty"></p-sortIcon>
                </th>
                <th pSortableColumn="damagedQty" style="width: 15%" *ngIf="IsDamageQty && (IsUnit || IsBatch)">
                    Damage Qty <p-sortIcon field="damagedQty"></p-sortIcon>
                </th>
                <th pSortableColumn="{{Id1Name}}" style="width: 20%" *ngIf="IsSerialized && UniqueIdCount >= 1">
                    {{Id1Name}} 
                </th>
                <th pSortableColumn="{{Id2Name}}" style="width: 20%" *ngIf="IsSerialized && UniqueIdCount >= 2">
                    {{Id2Name}} 
                </th>
                <th pSortableColumn="{{Id3Name}}" style="width: 20%" *ngIf="IsSerialized && UniqueIdCount >= 3">
                    {{Id3Name}} 
                </th>
                <th pSortableColumn="location" style="width: 20%">
                    Location <p-sortIcon field="location"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-expanded="expanded" let-i="rowIndex">
            <tr (click)="toggleRowExpansion(i)">
                <td>
                    <button
                        type="button"
                        pButton
                        pRipple
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expandedRowIndex === i ? 'bi bi-caret-down-fill' : 'bi bi-caret-right-fill'"
                    ></button>
                </td>
                <td>{{ i + 1 }}</td>
                <td>{{ item.itemCode }}</td>
                <td>{{ item.itemName }}</td>
                <td>{{ item.auditorName }}</td>
                <td *ngIf="IsBatch">{{ item.batchNo }}</td>
                <td *ngIf="IsUnit || IsBatch">{{ item.auditedQty }}</td>
                <td *ngIf="IsDamageQty && (IsUnit || IsBatch)">{{ item.damagedQty }}</td>
                <td *ngIf="IsSerialized && UniqueIdCount >= 1">{{ item.id1Name }}</td>
                <td *ngIf="IsSerialized && UniqueIdCount >= 2">{{ item.id2Name }}</td>
                <td *ngIf="IsSerialized && UniqueIdCount >= 3">{{ item.id3Name }}</td>
                <td>{{ item.auditStockLocation }}</td>
            </tr>
            <!-- Expanded Row with Nested Table -->
            <tr *ngIf="expandedRowIndex === i && (IsUnit || IsBatch)">
                <td colspan="9">
                    <p-table class="auditHistory" [value]="item.stockAuditHistory" [tableStyle]="{'min-width': '50rem'}">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>S.No</th>
                                <th>Audited Qty</th>
                                <th *ngIf="IsDamageQty">Damage Qty</th>
                                <th>Location</th>
                                <th>Date</th>
                                <th *ngIf="IsAuditor">Action</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-history let-h="rowIndex">
                            <tr>
                                <td>{{ h + 1 }}</td>
                                <td>{{ history.AuditedQty }}</td>
                                <td *ngIf="IsDamageQty">{{ history.DamagedQty }}</td>
                                <td>{{ history.StockLocation }}</td>
                                <td>{{ history.CurrentDate | date:'yyyy-MM-dd HH:mm:ss' }}</td>
                                <td *ngIf="IsAuditor">
                                    <img
                                        class="deleteIcon ml-2"
                                        src="assets/sidemenuicons/delete_icon.svg"
                                        title="Delete Item"
                                        (click)="deleteItem(item.auditLineItemsId, history.Id)"
                                    />
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<app-spinner></app-spinner>
<p-confirmDialog
    [style]="{ width: '35vw' }"
    [baseZIndex]="10000"
></p-confirmDialog>
<ng-container
  *ngIf="
    parentData != {} &&
    parentData.auditObj != null &&
    parentData.listData != null
  "
>
  <p-dialog
    header="View Summary"
    [modal]="true"
    [(visible)]="isAuditorSummary"
    [draggable]="false"
    [resizable]="false"
    [style]="{ width: '85vw', height: '90%' }"
  >
    <app-auditor-summary [parentData]="parentData"></app-auditor-summary>
  </p-dialog>
</ng-container>