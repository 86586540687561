import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/assets/utilities/guards/auth.guard';
import { HomeGuard } from 'src/assets/utilities/guards/home.guard';
import { AuditMobileComponent } from './home/audit/audit-mobile/audit-mobile.component';

const AuthModule = () => import('./auth/auth.module').then((m) => m.AuthModule);
const HomeModule = () =>
  import('./home/layout/layout.module').then((m) => m.LayoutModule);

const AddCompanyModule = () =>
  import('./home/shared/add-company/add-company.module').then(
    (m) => m.AddCompanyModule
  );

const AddAdminDetailsModule = () =>
  import('./home/admin-user/add-admin-details/add-admin-details.module').then(
    (m) => m.AddAdminDetailsModule
  );

const welcomeModule = () =>
  import('./home/welcome-assistance/welcome-assistance.module').then(
    (m) => m.WelcomeAssistanceModule
  );

const routes: Routes = [
  {
    path: '',
    redirectTo: 'authorization/login',
    pathMatch: 'full',
  },
  { path: 'stockAudit', component: AuditMobileComponent },
  { path: 'crossAudit', component: AuditMobileComponent },
  { path: 'authorization', loadChildren: AuthModule, canActivate: [HomeGuard] },
  { path: 'home', loadChildren: HomeModule, canActivate: [AuthGuard] },


  {
    path: 'adminDetails',
    loadChildren: AddAdminDetailsModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'welcomeAssistance',
    loadChildren: welcomeModule,
    canActivate: [AuthGuard],
  },
  // { path: '**', component: PageNotFoundComponent }
  // { path: '**', redirectTo: '/error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
