import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ItemsService } from 'src/app/services/items.service';
import { ToastrService } from 'ngx-toastr';
import { ItemCategoryService } from 'src/app/services/item-category.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToastrMsgService } from 'src/app/services/MainService/toastr-msg.service';

@Component({
  selector: 'app-add-item-popup',
  templateUrl: './add-item-popup.component.html',
  styleUrls: ['./add-item-popup.component.css'],
})
export class AddItemPopupComponent {
  @Input() parentData: any;
  @Output() childButtonClick = new EventEmitter<any>();

  myForm!: FormGroup;
  IsSubmit: any = false;
  storageObj: any;
  categoryList: any[] = [];
  filterItemsList: any[] = [];
  storageList: any[] = [];
  aisleList: any[] = [];
  bayList: any[] = [];
  fieldName: any = 'storageName';
  aisleObj: any;
  bayObj: any;

  constructor(
    private commonService: CommonService,
    private itemsService: ItemsService,
    private itemCategoryService: ItemCategoryService,
    private storageService: StorageService,
    private toastr: ToastrService,
    private toastrmsg :ToastrMsgService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getAllStorage();
    this.getAllItemsCategory();
    // this.myForm.setValue({
    //   itemCode : this.parentData.itemCode
    // });
  }
  getAllItemsCategory() {
    this.itemCategoryService
      .getAllItemsCategory(this.parentData.localData.companyId)
      .subscribe((data: any) => {
        this.categoryList = data;
      });
  }
  getAllStorage() {
    this.storageService
      .getAllStorage(this.parentData.localData.companyId)
      .subscribe((data: any) => {
        this.storageList = data;
      });
  }

  async getAllAisle(storageId: any, code: any): Promise<any[]> {
    try {
       await this.storageService
        .aisleGetAll(storageId, code)
        .subscribe({
          next:(res:any)=>{
            if(res.status){
              this.aisleList = res.data;
            }else{

            }
          },error:(err:any)=>{

          }
        });
      return this.aisleList;
    } catch (error) {
      console.error('Error fetching aisles:', error);
      throw error;
    }
  }
  async getAllBay(storageId: any, aisleCode: any): Promise<any[]> {
    try {
      this.bayList = await this.storageService
        .bayGetAll(storageId, aisleCode)
        .toPromise();
      return this.bayList;
    } catch (error) {
      console.error('Error fetching bays:', error);
      throw error;
    }
  }
  createForm() {
    this.myForm = new FormGroup({
      itemCategoryId: new FormControl('', [Validators.required]),
      itemCode: new FormControl(this.parentData.itemCode, [
        Validators.required,
      ]),
      itemName: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      costPrice: new FormControl(''),
      sellPrice: new FormControl(''),
      uom: new FormControl(''),
      abcClassification: new FormControl(''),
      fmsClassification: new FormControl(''),
      taxHsn: new FormControl(''),
      storage: new FormControl(''),
      eanQr: new FormControl(
        this.parentData.eanQr ? this.parentData.eanQr : ''
      ),
    });
  }
  close() {
    this.parentData.showPopup = false;
  }

  // onSubmit() {
  //   this.IsSubmit = true;
  //   if (this.myForm.valid) {
  //     // console.log(this.myForm.value);
  //     let data: any = {};
  //     data = this.myForm.value;
  //     data = {
  //       ...this.myForm.value,
  //       CompanyId: this.parentData.localData.companyId,
  //       CreatedBy: this.parentData.localData.userId,
  //       UpdatedBy: this.parentData.localData.userId,
  //     };
  //     if (data.storage) {
  //       if (this.storageObj && this.storageObj.storageSplitter) {
  //         let split = data.storage.split(`${this.storageObj.storageSplitter}`);
  //         // console.log('split', split);
  //         if (split[split.length - 1] == '') {
  //           if (
  //             this.commonService.getLastLetter(data.storage) ==
  //             this.storageObj.storageSplitter
  //           ) {
  //             let storage = this.commonService.removeLastLetter(data.storage);
  //             data.storage = storage.trim();
  //           }
  //         }
  //       }
  //     }
  //     if(data.costPrice == '')
  //         data.costPrice = 0;
  //     if(data.sellPrice == '')
  //       data.sellPrice = 0;
  //     this.itemsService.insertItems(data).subscribe({
  //       next: () => {
  //         this.showSuccess('Saved');
  //         this.close();
  //         this.childButtonClick.emit("save");
  //       },
  //       error: (error) => {
  //         // console.log('Form submission error', error);
  //       },
  //     });
  //   }
  // }

  onSubmit() {
    this.IsSubmit = true;

    if (!this.myForm.valid) return;

    let data = {
      ...this.myForm.value,
      CompanyId: this.parentData.localData.companyId,
      CreatedBy: this.parentData.localData.userId,
      UpdatedBy: this.parentData.localData.userId,
      storage: this.formatStorage(this.myForm.value.storage),
      costPrice: this.myForm.value.costPrice || 0,
      sellPrice: this.myForm.value.sellPrice || 0,
    };

    this.itemsService.insertItems(data).subscribe({
      next: (res:any) => {
        if(res.status){
          this.showSuccess('Saved');
          this.close();
          this.childButtonClick.emit('save');
        }else{
          if(res.message.includes("300")){
            this.toastrmsg.showInfo(res.message.replace("300",""))
          }
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  private formatStorage(storage: string): string {
    if (!storage || !this.storageObj?.storageSplitter) return storage;

    let split = storage.split(`${this.storageObj.storageSplitter}`);
    if (
      split[split.length - 1] === '' &&
      this.commonService.getLastLetter(storage) ===
        this.storageObj.storageSplitter
    ) {
      return this.commonService.removeLastLetter(storage).trim();
    }

    return storage;
  }

  showSuccess(msg: string) {
    this.toastr.success(msg + ' Succussfully', '', {
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
    });
  }
  async onItemSelect() {
    if (typeof this.myForm.value.storage === 'object') {
      let bind: any;
      let aisles: any = [];
      let bays: any = [];
      if (this.myForm.value.storage.storageShortCode) {
        this.storageObj = this.myForm.value.storage;
        let code = null;
        aisles = await this.getAllAisle(this.storageObj.storageId, code);

        if (aisles && aisles.length > 0) {
          bind = `${this.storageObj.storageShortCode} ${this.storageObj.storageSplitter} `;
          this.myForm.controls['storage'].setValue(bind);
        }
      } else if (this.myForm.value.storage.aisleCode) {
        this.aisleObj = this.myForm.value.storage;
        bays = await this.getAllBay(
          this.storageObj.storageId,
          this.aisleObj.aisleCode
        );

        if (bays && bays.length > 0) {
          bind = `${this.storageObj.storageShortCode} ${this.storageObj.storageSplitter} ${this.aisleObj.aisleCode} ${this.storageObj.storageSplitter} `;
        } else
          bind = `${this.storageObj.storageShortCode} ${this.storageObj.storageSplitter} ${this.aisleObj.aisleCode}`;

        this.myForm.controls['storage'].setValue(bind);
      } else if (this.myForm.value.storage.bayCode) {
        this.bayObj = this.myForm.value.storage;

        bind = `${this.storageObj.storageShortCode} ${this.storageObj.storageSplitter} ${this.aisleObj.aisleCode} ${this.storageObj.storageSplitter} ${this.bayObj.bayCode} ${this.storageObj.storageSplitter}`;

        this.myForm.controls['storage'].setValue(bind);
      }
    }
  }
  // filterItems(event: any) {
  //   // console.log('storage', this.myForm.value.storage);
  //   let filtered: any[] = [];
  //   let query = event.query.toLowerCase();
  //   let split: any;
  //   if (this.storageObj && this.storageObj.storageSplitter) {
  //     split = query.split(`${this.storageObj.storageSplitter}`);
  //   }
  //   let IsStorage = false;
  //   let IsAisle = false;
  //   let IsBay = false;
  //   if (split && split.length > 0) {
  //     if (split.length == 1) {
  //       IsStorage = true;
  //       query = split[0].trim();
  //       this.fieldName = 'storageShortCode';
  //     } else if (split.length == 2) {
  //       IsAisle = true;
  //       query = split[1].trim();
  //       this.fieldName = 'aisleCode';
  //     } else if (split.length == 3) {
  //       IsBay = true;
  //       query = split[2].trim();
  //       this.fieldName = 'bayCode';
  //     }
  //   } else {
  //     IsStorage = true;
  //     this.fieldName = 'storageShortCode';
  //   }
  //   if (IsStorage) {
  //     for (let i = 0; i < this.storageList.length; i++) {
  //       let row = this.storageList[i];

  //       if (
  //         row.storageShortCode &&
  //         row.storageShortCode.toLowerCase().indexOf(query) === 0
  //       ) {
  //         filtered.push(row);
  //       }
  //     }
  //   } else if (IsAisle) {
  //     if (this.aisleList.length > 0) {
  //       for (let i = 0; i < this.aisleList.length; i++) {
  //         let row = this.aisleList[i];
  //         if (
  //           row.aisleCode &&
  //           row.aisleCode.toLowerCase().indexOf(query) === 0
  //         ) {
  //           filtered.push(row);
  //         } else if (query == '') filtered.push(row);
  //       }
  //     }
  //   } else if (IsBay) {
  //     if (this.bayList.length > 0) {
  //       for (let i = 0; i < this.bayList.length; i++) {
  //         let row = this.bayList[i];
  //         if (row.bayCode && row.bayCode.toLowerCase().indexOf(query) === 0) {
  //           filtered.push(row);
  //         } else if (query == '') filtered.push(row);
  //       }
  //     }
  //   }
  //   this.filterItemsList = filtered;
  // }

  filterItems(event: any) {
    const query = event.query.toLowerCase();
    const split = this.splitQuery(query);
    const filterType = this.determineFilterType(split);

    const filtered = this.getFilteredList(filterType, split);
    this.filterItemsList = filtered;
  }

  private splitQuery(query: string): string[] {
    return this.storageObj?.storageSplitter
      ? query.split(this.storageObj.storageSplitter)
      : [query];
  }

  private determineFilterType(split: string[]): string {
    if (split.length === 1) {
      this.fieldName = 'storageShortCode';
      return 'storage';
    } else if (split.length === 2) {
      this.fieldName = 'aisleCode';
      return 'aisle';
    } else if (split.length === 3) {
      this.fieldName = 'bayCode';
      return 'bay';
    }
    return 'storage';
  }

  private getFilteredList(filterType: string, split: string[]): any[] {
    const query = split[split.length - 1]?.trim() || '';
    switch (filterType) {
      case 'aisle':
        return this.filterByCode(this.aisleList, 'aisleCode', query);
      case 'bay':
        return this.filterByCode(this.bayList, 'bayCode', query);
      default:
        return this.filterByCode(this.storageList, 'storageShortCode', query);
    }
  }

  private filterByCode(list: any[], codeField: string, query: string): any[] {
    return list.filter(
      (row) => row[codeField]?.toLowerCase().startsWith(query) || query === ''
    );
  }
}
