import { Component, Input, OnInit } from '@angular/core';
import { error, log } from 'console';
import { AuditService } from 'src/app/services/audit.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-auditor-summary',
  templateUrl: './auditor-summary.component.html',
  styleUrls: ['./auditor-summary.component.css']
})
export class AuditorSummaryComponent implements OnInit{

  @Input() parentData : any;
  auditId : any;
  auditPlanId : any;
  auditors : any;
  auditObject : any = {};
  userList: any [] = [];
  auditorSummaryList: any;
  auditedItems: any;
 
  constructor(private auditService : AuditService, private usersService : UsersService){
  }

  ngOnInit(){
    console.log(this.parentData, 'ngOninit');
    this.auditObject = this.parentData.auditObj;
    this.auditedItems = this.parentData.listData;
    this.initialMethods();
  }

  initialMethods() {
    this.getAllUser();
    this.getAuditorSummary();
  }

  getAllUser() {
    this.usersService.getUserList().subscribe({
      next: (resp: any) => {
        if (resp.status) {
          this.userList = resp.data;
        }
      }
    });
  }

  // DO NOT REMOVE THIS CODE
  
//   getAuditorSummary() {
//     let data: any = {};
//     data.auditId = this.auditObject.auditId;
//     data.auditPlanId = this.auditObject.auditPlanId;
//     data.auditors = this.auditObject.auditors;
  
//     this.auditService.getAuditroSummary(data).subscribe({
//         next: (res: any) => {
//             if (res.status) {
//                 this.auditorSummaryList = res.data;
  
//                 this.auditorSummaryList.forEach((item: any) => {
//                     const auditorId = item.auditor;
//                     const user = this.userList.find(
//                         (user: any) => user.userId === auditorId
//                     );
//                     if (user) {
//                         item.auditorName = `${user.firstName} ${user.lastName}`;
//                     }
  
//                     const itemCodes = Array.isArray(item.itemDetails)
//                         ? item.itemDetails.map((detail: any) => detail.itemCode)
//                         : [];
                    
//                     const auditedForThisAuditor = Array.isArray(item.allItemDetails)
//                         ? item.allItemDetails.filter(
//                             (allItem: any) => allItem.auditedQty != null
//                         )
//                         : [];

//                     const totalAuditedStockQty = auditedForThisAuditor.reduce(
//                         (sum: number, auditedItem: any) => sum + auditedItem.stockQty, 0
//                     );
  
//                     item.auditedCount = totalAuditedStockQty;
  
//                     item.remainingCount = item.assignedInventoryCount - totalAuditedStockQty;
  
//                     if (item.auditedCount == 0 && item.remainingCount == item.assignedInventoryCount) {
//                         item.status = 'Yet to Start';
//                     } else if (item.auditedCount > 0 && item.remainingCount > 0) {
//                         item.status = 'In Progress';
//                     } else if (item.auditedCount > 0 && item.remainingCount == 0) {
//                         item.status = 'Completed';
//                     }
//                 });
  
//                 console.log('Updated auditorSummaryList with audit counts: ', this.auditorSummaryList);
//             }
//         },
//         error(err: any) {
//             console.log('Error fetching Auditor Summary' + err);
//         }
//     });
// }


// getAuditorSummary() {
//   let data: any = {};
//   data.auditId = this.auditObject.auditId;
//   data.auditPlanId = this.auditObject.auditPlanId;
//   data.auditors = this.auditObject.auditors;

//   this.auditService.getAuditroSummary(data).subscribe({
//       next: (res: any) => {
//           if (res.status) {
//               this.auditorSummaryList = res.data;

//               this.auditorSummaryList.forEach((item: any) => {
//                   const auditorId = item.auditor;
//                   const user = this.userList.find(
//                       (user: any) => user.userId === auditorId
//                   );
//                   if (user) {
//                       item.auditorName = `${user.firstName} ${user.lastName}`;
//                   }

//                   const auditedForThisAuditor = Array.isArray(item.allItemDetails)
//                       ? item.allItemDetails.filter(
//                           (allItem: any) => allItem.auditedQty != null
//                       )
//                       : [];

//                   const totalAuditedStockQty = auditedForThisAuditor.reduce(
//                       (sum: number, auditedItem: any) => sum + auditedItem.auditedQty, 0
//                   );

//                   item.auditedCount = totalAuditedStockQty;
//                   item.remainingCount = item.assignedInventoryCount - totalAuditedStockQty;

//                   // const allStockAuditHistories = auditedForThisAuditor
//                   //     .flatMap((auditedItem: any) => auditedItem.stockAuditHistory || [])
//                   //     .map((history: any) => new Date(history.currentDate))
//                   //     .filter((date: Date) => !isNaN(date.getTime()))
//                   //     .sort((a: Date, b: Date) => a.getTime() - b.getTime());

//                   // if (allStockAuditHistories.length > 0) {
//                   //     const duration = (allStockAuditHistories[allStockAuditHistories.length - 1].getTime() - allStockAuditHistories[0].getTime()) / 1000; // in seconds
//                   //     const hours = Math.floor(duration / 3600);
//                   //     const minutes = Math.floor((duration % 3600) / 60);
//                   //     item.timer = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
//                   // } else {
//                   //     item.timer = '0:00';
//                   // }

//                   
//                   const allStockAuditHistories = auditedForThisAuditor
//                         .flatMap((auditedItem: any) => auditedItem.stockAuditHistory || [])
//                         .map((history: any) => new Date(history.currentDate))
//                         .filter((date: Date) => !isNaN(date.getTime()))
//                         .sort((a: Date, b: Date) => a.getTime() - b.getTime());

//                     
//                     if (allStockAuditHistories.length > 0) {
//                         const currentTime = new Date();
//                         const firstAuditTime = allStockAuditHistories[0];
//                         const latestAuditTime = allStockAuditHistories[allStockAuditHistories.length - 1];

//                         const duration = (latestAuditTime.getTime() < currentTime.getTime() 
//                             ? currentTime.getTime() - firstAuditTime.getTime() 
//                             : latestAuditTime.getTime() - firstAuditTime.getTime()) / 1000;
                        
//                         const hours = Math.floor(duration / 3600);
//                         const minutes = Math.floor((duration % 3600) / 60);
//                         item.timer = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
//                     } else {
//                         item.timer = '0:00';
//                     }

//                     if (item.auditorStatus === "Completed") {
//                       item.status = 'Completed';
//                   } else if (item.auditedCount === 0 && item.remainingCount === item.assignedInventoryCount) {
//                       item.status = 'Yet to Start';
//                   } else if (item.auditedCount > 0 && item.remainingCount > 0) {
//                       item.status = 'In Progress';
//                   } else if (item.auditedCount > 0 && item.remainingCount === 0) {
//                       item.status = 'Completed';
//                   }
//               });
//           }
//       },
//       error(err: any) {
//           console.log('Error fetching Auditor Summary' + err);
//       }
//   });
// }

 // END --- DO NOT REMOVE THIS CODE

getAuditorSummary() {
  let data: any = {};
  data.auditId = this.auditObject.auditId;
  data.auditPlanId = this.auditObject.auditPlanId;
  data.auditors = this.auditObject.auditors;

  this.auditService.getAuditroSummary(data).subscribe({
      next: (res: any) => {
          if (res.status) {
              this.auditorSummaryList = res.data;

              this.auditorSummaryList.forEach((item: any) => {
                  const auditorId = item.auditor;
                  const user = this.userList.find(
                      (user: any) => user.userId === auditorId
                  );
                  if (user) {
                      item.auditorName = `${user.firstName} ${user.lastName}`;
                  }

                  const auditedForThisAuditor = Array.isArray(item.allItemDetails)
                      ? item.allItemDetails.filter(
                          (allItem: any) => allItem.auditedQty != null
                      )
                      : [];

                  const totalAuditedStockQty = auditedForThisAuditor.reduce(
                      (sum: number, auditedItem: any) => sum + auditedItem.auditedQty, 0
                  );

                  item.auditedCount = totalAuditedStockQty;
                  item.remainingCount = item.assignedInventoryCount - totalAuditedStockQty;

                  const allStockAuditHistories = auditedForThisAuditor
                      .flatMap((auditedItem: any) => auditedItem.stockAuditHistory || [])
                      .map((history: any) => new Date(history.currentDate))
                      .filter((date: Date) => !isNaN(date.getTime()))
                      .sort((a: Date, b: Date) => a.getTime() - b.getTime());

                  if (allStockAuditHistories.length > 0) {
                      const currentTime = new Date();
                      const firstAuditTime = allStockAuditHistories[0];
                      const statusUpdatedTime = new Date(item.statusUpdatedDate);

                      let duration;
                      if (item.auditorStatus === "Completed") {
                          duration = (firstAuditTime.getTime() < statusUpdatedTime.getTime()
                              ? statusUpdatedTime.getTime() - firstAuditTime.getTime()
                              : currentTime.getTime() - firstAuditTime.getTime()) / 1000; 
                      } else {
                          duration = (currentTime.getTime() - firstAuditTime.getTime()) / 1000; 
                      }

                      const hours = Math.floor(duration / 3600);
                      const minutes = Math.floor((duration % 3600) / 60);
                      item.timer = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
                  } else {
                      item.timer = '0:00';
                  }

                  if (item.auditorStatus === "Completed") {
                      item.status = 'Completed';
                  } else if (item.auditedCount === 0 && item.remainingCount === item.assignedInventoryCount) {
                      item.status = 'Yet to Start';
                  } else if (item.auditedCount > 0 && item.remainingCount > 0) {
                      item.status = 'In Progress';
                  } else if (item.auditedCount > 0 && item.remainingCount === 0) {
                      item.status = 'Completed';
                  }
              });
          }
      },
      error(err: any) {
          console.log('Error fetching Auditor Summary' + err);
      }
  });
}


}
