import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { fadeInOut, INavbarData } from './helper';
import { navbarData } from './nav-data';
import { RolesService } from 'src/app/services/roles.service';
import { SideNavService } from './side-nav.service';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
  animations: [
    fadeInOut,
    trigger('rotate', [
      transition(':enter', [
        animate(
          '1000ms',
          keyframes([
            style({ transform: 'rotate(0deg)', offset: '0' }),
            style({ transform: 'rotate(2turn)', offset: '1' }),
          ])
        ),
      ]),
    ]),
  ],
})
export class SidenavComponent implements OnInit {
  @Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
  collapsed = false;
  screenWidth = 0;
  navData: INavbarData[] = [];
  multiple: boolean = false;
  isPopoverOpen = false;
  role: any;
  roleId: any;
  CompanyId: any;
  accessCodes: any;
  isAdmin: any;
  mouseHoverBoolean = false;
  expandedIndex: number | null = null;

  isClicked: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 768) {
      this.collapsed = false;
      this.onToggleSideNav.emit({
        collapsed: this.collapsed,
        screenWidth: this.screenWidth,
      });
    }
  }

  constructor(
    public router: Router,
    private roles: RolesService,
    private side: SideNavService
  ) {}

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    const credentials: any = localStorage.getItem('credentials');
    const createdBy = JSON.parse(credentials);
    this.roleId = createdBy.roleId;
    this.CompanyId = createdBy.companyId;
    this.isAdmin = createdBy.isAdmin;
    this.getRoleAccess();
    this.side.triggerFunction$.subscribe(() => {
      this.closeSidenav();
    });
  }

  filterNavDataByRole(
    navData: INavbarData[],
    accessCodes: any[],
    isAdmin: boolean
  ): INavbarData[] {
    const accessCodeNames = accessCodes.map((code) => code.accessCodeName);
    const hasPermission = (permissions: string | string[]): boolean => {
      if (Array.isArray(permissions)) {
        return permissions.some((permission) =>
          accessCodeNames.includes(permission)
        );
      }
      return accessCodeNames.includes(permissions);
    };

    return navData
      .map((item) => {
        if (item.isAdmin) {
          return isAdmin ? item : null;
        }
        if (item.items && item.items.length > 0) {
          const filteredChildren = item.items.filter((child) =>
            hasPermission(child.permission)
          );
          if (filteredChildren.length > 0) {
            return { ...item, items: filteredChildren };
          }
        } else if (hasPermission(item.permission)) {
          return { ...item };
        }
        return null;
      })
      .filter((item) => item !== null) as INavbarData[];
  }

  getRoleAccess() {
    this.roles.getAccessCodes().subscribe((resp) => {
      if (resp.status) {
        this.accessCodes = resp.data;
        if (this.accessCodes) {
          this.navData = this.filterNavDataByRole(
            navbarData,
            this.accessCodes,
            this.isAdmin
          );
        }
      }
    });
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    this.mouseHoverBoolean = false;
    this.onToggleSideNav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
    this.side.triggerForPopupsFunction();
  }

  closeSidenav(): void {
    this.collapsed = false;
    this.onToggleSideNav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
  }

  handleClick(event: any, item: INavbarData): void {
    if (!this.collapsed) {
      this.isPopoverOpen = false;
      item.isPopoverOpen = !item.isPopoverOpen;
    }
    this.shrinkItems(event, item);
    item.expanded = !item.expanded;
    event.stopPropagation();
  }

  mouseHover(expand: boolean, index: number): void {
    this.expandedIndex = expand ? index : null;
    this.mouseHoverBoolean = expand;
  }

  // hoverclick(expand: boolean, index: number): void {
  //   if (expand) {
  //     this.expandedIndex = index;
  //     this.isClicked = true;
  //   } else if (!this.isClicked) {
  //     this.expandedIndex = null;
  //   }
  // }

  mouseHoverSidenav() {
    this.mouseHoverBoolean = false;
  }

  getActiveClass(data: INavbarData): string {
    return this.router.url.includes(data.routeLink) ? 'active' : '';
  }

  shrinkItems(event: any, item: INavbarData): void {
    if (!this.multiple) {
      for (let modelItem of this.navData) {
        if (item !== modelItem && modelItem.expanded) {
          modelItem.expanded = false;
          modelItem.isPopoverOpen = false;
        }
      }
    }
    event.stopPropagation();
  }

  getColumnCount(data: any): number {
    return data.items.length > 6 ? 3 : 2; // Adjust as needed
  }
}
