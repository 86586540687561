import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
// import { ConfirmationService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastrMsgService {

  constructor(private toastr:ToastrService
    // ,
    // private confirmationService: ConfirmationService
  ) { }
  showSuccess(title :string,msg ?:string) {
    this.toastr.success(title, msg, {
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
    });
  }
  showError(title :string,msg ?:string) {
    this.toastr.error(title, msg, {
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
    });
  }
  showInternalError() {
    this.toastr.error("Please Contact Admin", "Internal Error!", {
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
    });
  }
  showInfo(title :string,msg ?:string) {
    this.toastr.info(title, msg, {
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
    });
  }
  showWarning(title :string,msg ?:string) {
    this.toastr.warning(title, msg, {
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
    });
  }
  // delete(): Promise<boolean> {
  //   return new Promise((resolve) => {
  //     this.confirmationService.confirm({
  //       message: 'Are you sure that you want to proceed?',
  //       header: 'Confirmation',
  //       icon: 'bi bi-exclamation',
  //       acceptLabel: 'Delete',
  //       rejectLabel: 'Cancel',
  //       accept: () => {
  //         resolve(true);  // Return true if the user accepts
  //       },
  //       reject: () => {
  //         resolve(false); // Return false if the user rejects
  //       },
  //     });
  //   });
  // }

}
