import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeadernameService {
  private headerNameSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  headerName$ = this.headerNameSubject.asObservable();

  constructor() {}

  loadHeaderName(name: any) {
    this.headerNameSubject.next(name);
  }
}
