import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { Router } from '@angular/router';

const baseUrl = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(private http: HttpClient, private router: Router) {}

  login(obj: any) {
    return this.http.post<any>(`${baseUrl}/Login/login`, obj);
  }

  getCountry() {
    return this.http.get<any>(`${baseUrl}/SignUp/getAllCountry`);
  }

  getBusinessType() {
    return this.http.get<any>(`${baseUrl}/SignUp/getAllBusiness`);
  }

  getPlatform() {
    return this.http.get<any>(`${baseUrl}/SignUp/getAllPlatform`);
  }

  signUp(obj: any) {
    return this.http.post<any>(`${baseUrl}/SignUp/create`, obj);
  }
  forgotPassword(obj: any) {
    return this.http.post<any>(`${baseUrl}/Login/forgot-password`, obj);
  }
  resetPassword(obj: any) {
    return this.http.post<any>(`${baseUrl}/Login/reset-password`, obj);
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/authorization/login']);
  }

  isAuthenticated(): boolean {
    const userCredentials = localStorage.getItem('credentials');
    return userCredentials != null;
  }
}
