import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { InterceptorService } from 'src/assets/utilities/services/interceptor/interceptor.service';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './home/layout/layout.component';
import { SidenavComponent } from './home/sidenav/sidenav.component';
import { SublevelMenuComponent } from './home/sidenav/sublevel-menu.component';
import { HeaderComponent } from './home/layout/header/header.component';
import { FooterComponent } from './home/layout/footer/footer.component';
import { ToastrModule } from 'ngx-toastr';
import { BadgeModule } from 'primeng/badge';
import { PrimeModule } from './prime-module/prime-module.module';
import { WebcamModule } from 'ngx-webcam';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    SidenavComponent,
    SublevelMenuComponent,
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    // SharedModule,
    BadgeModule,
    ToastrModule.forRoot(),
    PrimeModule,
    WebcamModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
