import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const baseUrl = `${environment.apiUrl}/auditLineItems`;

@Injectable({
  providedIn: 'root'
})
export class AuditLineItemsService {

  constructor(private http: HttpClient) { }

  getAllAuditLineItems(id : string, companyId:string) {
    return this.http.get<any[]>(`${baseUrl}/getAllAuditLineItems/${id}/${companyId}`);
  }
  getAllAuditLineItemDetails(auditId : string, companyId : string, userId : string) {
    return this.http.get<any[]>(`${baseUrl}/getAllAuditLineItemDetails/${auditId}/${companyId}/${userId}`);
  }
  getAllAuditedLineItems(id : string, companyId:string) {
    return this.http.get<any[]>(`${baseUrl}/getAllAuditedLineItems/${id}/${companyId}`);
  }
  getAllItemsByCodeNameEan(data : any) {
    return this.http.post<any[]>(`${baseUrl}/getAllItemsByCodeNameEan`, data);
  }
  getAuditLineItems(id : string, companyId : string) {
    return this.http.get<any[]>(`${baseUrl}/getAuditLineItems/${id}/${companyId}`);
  }
  insertAuditLineItems(data : any) {
    return this.http.post<any[]>(`${baseUrl}/insertAuditLineItems`, data);
  }
  updateAuditLineItems(id : string, companyId : string, data : any) {
    return this.http.put<any[]>(`${baseUrl}/updateAuditLineItems/${id}/${companyId}`, data);
  }
  updateLineItemsDeclaration(data : any) {
    return this.http.put<any[]>(`${baseUrl}/updateLineItemsDeclaration`, data);
  }
  bulkInsertAuditLineItems(data : any) {
    return this.http.post<any[]>(`${baseUrl}/bulkInsertAuditLineItems`, data);
  }
  deleteAuditLineItems(id : string, companyId:string) {
    return this.http.delete<any[]>(`${baseUrl}/deleteAuditLineItem/${id}/${companyId}`);
  }
  resetStockReport(id : number, companyId:string) {
    return this.http.put<any[]>(`${baseUrl}/resetStockReport/${id}/${companyId}`, null);
  }
  deleteAuditLineItemHistory(auditLineItemsId : string, id : string, type : any, companyId : string) {
    return this.http.put<any[]>(`${baseUrl}/deleteAuditLineItemHistory/${auditLineItemsId}/${id}/${type}/${companyId}`, null);
  }
  CameraCheck(id : number,ItemCode : string) {
    return this.http.get(`${baseUrl}/CameraCheck/${id}/${ItemCode}`);
  }
}
