import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const baseUrl = `${environment.apiUrl}/itemsCategory`;

@Injectable({
  providedIn: 'root',
})
export class ItemCategoryService {
  constructor(private http: HttpClient) {}

  getAllItemsCategory(id: string) {
    return this.http.get<any[]>(`${baseUrl}/itemsCategoryGetAll/${id}`);
  }
  itemsCategoryByItemGroupId(id: string) {
    return this.http.get(`${baseUrl}/itemsCategoryByItemGroupId/${id}`);
  }
  getItemsCategory(id: string) {
    return this.http.get<any[]>(`${baseUrl}/itemsCategoryGet/${id}`);
  }
  insertItemsCategory(data: any) {
    return this.http.post<any[]>(`${baseUrl}/itemsCategoryCreate`, data);
  }
  updateItemsCategory(id: string, data: any) {
    return this.http.put<any[]>(`${baseUrl}/itemsCategoryUpdate/${id}`, data);
  }
  deleteItemsCategory(id: string) {
    return this.http.put<any[]>(`${baseUrl}/itemsCategoryDelete/${id}`, null);
  }
}
