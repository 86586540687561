import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MsclarityService {
  constructor() {}

  loadClarity() {
    if (environment.production) {
      (function (
        c: Record<string, any>,
        l: Document,
        a: string,
        r: string,
        i: string,
        t?: HTMLScriptElement,
        y?: Node
      ) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r) as HTMLScriptElement;
        t.async = true;
        t.src = 'https://www.clarity.ms/tag/' + i;
        y = l.getElementsByTagName(r)[0];
        y?.parentNode?.insertBefore(t, y);
      })(window as any, document, 'clarity', 'script', 'p5l8abxbdp');
    }
  }
}
