import { Component, OnInit } from '@angular/core';
import { MsclarityService } from './services/msclarity.service';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'stockount';
  private previousUrl: string | null = null;

  constructor(
    private msClarityService: MsclarityService,
    private router: Router
  ) {}

  ngOnInit() {
    this.msClarityService.loadClarity();
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationStart) {
    //     if (
    //       !event.url.startsWith('/home/salesInvoice') &&
    //       !event.url.startsWith('/home/purchase')
    //     ) {
    //       localStorage.removeItem('itemgroupid');
    //       localStorage.removeItem('itemgroupname');
    //     }
    //   }
    // });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const currentUrl = event.url;

        if (
          this.previousUrl?.startsWith('/home/salesInvoice') &&
          currentUrl.startsWith('/home/purchase')
        ) {
          localStorage.removeItem('itemgroupid');
          localStorage.removeItem('itemgroupname');
        } else if (
          this.previousUrl?.startsWith('/home/purchase') &&
          currentUrl.startsWith('/home/salesInvoice')
        ) {
          localStorage.removeItem('itemgroupid');
          localStorage.removeItem('itemgroupname');
        } else if (
          currentUrl !== '/home/salesInvoice' &&
          currentUrl !== '/home/purchase' &&
          currentUrl !== '/home/purchase/newpurchase' &&
          currentUrl !== '/home/salesInvoice/newSalesInvoice'
        ) {
          localStorage.removeItem('itemgroupid');
          localStorage.removeItem('itemgroupname');
        }
        this.previousUrl = currentUrl;
      }
    });
  }
}
