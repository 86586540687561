import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

const baseUrl = `${environment.apiUrl}/User`;
const baseUrlForRole = `${environment.apiUrl}`;
const baseUrlForBranch = `${environment.apiUrl}/Branch`;
@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient) {}

  getUserList() {
    return this.http.get<any>(`${baseUrl}/userGetAll`);
  }

  getUserIdividual(id: any) {
    // return this.http.get<any>(`${baseUrl}/userGet/${id}`);
    return this.http.get<any>(`${baseUrl}/UserGetById/${id}`);
  }
  getUserByRole(data: any) {
    return this.http.post<any>(`${baseUrl}/getUserByRole`, data);
  }
  addUser(obj: any) {
    return this.http.post<any>(`${baseUrl}/userCreate`, obj);
  }
  deleteUser(id: any) {
    return this.http.put<any>(`${baseUrl}/userDelete/${id}`, null);
  }
  updateUser(obj: any, Id: any) {
    return this.http.put<any>(`${baseUrl}/userUpdate/${Id}`, obj);
  }

  updateUserNew(obj: any, id: any) {
    return this.http.put<any>(`${baseUrl}/updateUser/${id}`, obj);
  }

  getRoles(companyId: any) {
    return this.http.get<any>(`${baseUrlForRole}/Role/roleGetAll/${companyId}`);
  }

  changepassword(obj: any, id: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    return this.http.put<any>(`${baseUrl}/resetPassword/${id}`, obj, {
      headers,
    });
  }

  getBranch(CompanyId: any) {
    return this.http.get<any>(`${baseUrlForBranch}/getBranchs/${CompanyId}`);
  }
}
