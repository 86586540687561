import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideNavService {
  private triggerFunctionSource = new Subject<void>();
  triggerFunction$ = this.triggerFunctionSource.asObservable();
  private triggerForPopups = new Subject<void>();
  triggerForPopups$ = this.triggerForPopups.asObservable();
  private reinitializeSubject = new Subject<void>();
  reinitialize$ = this.reinitializeSubject.asObservable();

  triggerFunction() {
    this.triggerFunctionSource.next();
  }
  triggerForPopupsFunction() {
    this.triggerForPopups.next();
  }

  triggerReinitialize() {
    this.reinitializeSubject.next();
  }
}
