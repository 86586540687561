import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  getLastLetter(input: string): string {
    if (input.length === 0) {
      return '';
    }
    return input.charAt(input.length - 1);
  }
  removeLastLetter(input: string): string {
    if (input.length === 0) {
      return input; // Return the original string if it's empty
    }
    return input.slice(0, -1);
  }
  calculateDateDifference(startDate: any, endDate: any): string {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
  
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      throw new Error('Invalid date(s) provided.');
    }
  
    let diffInMilliseconds = endDate.getTime() - startDate.getTime();
  
    const millisecondsInMinute = 1000 * 60;
    const millisecondsInHour = millisecondsInMinute * 60;
    const millisecondsInDay = millisecondsInHour * 24;
    const millisecondsInYear = millisecondsInDay * 365.25; // including leap years
  
    const years = Math.floor(diffInMilliseconds / millisecondsInYear);
    diffInMilliseconds -= years * millisecondsInYear;
  
    const days = Math.floor(diffInMilliseconds / millisecondsInDay);
    diffInMilliseconds -= days * millisecondsInDay;
  
    const hours = Math.floor(diffInMilliseconds / millisecondsInHour);
    diffInMilliseconds -= hours * millisecondsInHour;
  
    const minutes = Math.floor(diffInMilliseconds / millisecondsInMinute);
  
    // Construct the formatted string
    let formattedString = '';
  
    if (years > 0) {
      formattedString += `${years} year${years > 1 ? 's' : ''}, `;
    }
  
    if (days > 0) {
      formattedString += `${days} day${days > 1 ? 's' : ''}, `;
    }
  
    if (hours > 0) {
      formattedString += `${hours} hour${hours > 1 ? 's' : ''}, `;
    }
  
    if (minutes > 0) {
      formattedString += `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
  
    return formattedString.trim().replace(/,\s*$/, ''); // Remove any trailing comma and space
  }
}
