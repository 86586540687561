import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';

@Component({
  selector: 'app-barcode-scan',
  templateUrl: './barcode-scan.component.html',
  styleUrls: ['./barcode-scan.component.css'],
})
export class BarcodeScanComponent {
  @Input() parentData: any;
  @Output() childButtonClick = new EventEmitter<any>();

  @Input() set triggerFunction(value: boolean) {
    if (value) {
      this.restartScanner();
    }
  }

  @ViewChild('scannerbar', { static: false })
  scannerbar!: BarcodeScannerLivestreamComponent;
  barcodes: string | null = null;
  errorMessage: string | null = null;

  constructor() {}

  restartScanner(): void {
    this.startScanner();
  }

  startScanner(): void {
    try {
      this.scannerbar.start();
    } catch (error) {
      this.errorMessage =
        'An error occurred while starting the scanner. Please try again.';
      console.error('Scanner start error:', error);
    }
  }

  stopScanner(): void {
    try {
      this.scannerbar.stop();
      this.scannerbar.start();
      this.scannerbar.stop();
    } catch (error) {
      this.errorMessage =
        'An error occurred while stopping the scanner. Please try again.';
      console.error('Scanner stop error:', error);
    }
  }

  callChild() {
    let data: any = {};
    data.barcodes = this.barcodes;
    this.childButtonClick.emit(data);
  }

  onValueChanges(result: any): void {
    if (result && result.codeResult && result.codeResult.code) {
      this.barcodes = result.codeResult.code;
      this.errorMessage = null;
      this.callChild();
    } else {
      this.barcodes = null;
      this.callChild();
      this.errorMessage = 'Invalid barcode data. Please try again.';
    }
  }
}
