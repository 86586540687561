import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { end } from '@popperjs/core';

const baseUrl = `${environment.apiUrl}/audit`;

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private http: HttpClient) { }

  // getAllAudit(companyId:string, branchId:string, primaryAuditStatus:string, auditPlanId:string, startDate : any, endDate : any) {
  //   let params = new HttpParams()
  //   .set('companyId', companyId)
  //   .set('branchId', branchId)
  //   .set('primaryAuditStatus', primaryAuditStatus)
  //   .set('auditPlanId', auditPlanId);

  //   if (startDate) {
  //     params = params.set('startDate', startDate.toISOString());
  //   }

  //   if (endDate) {
  //     params = params.set('endDate', endDate.toISOString());
  //   }

  //   return this.http.get<any[]>(`${baseUrl}/getAllAudit` , { params });
  // }

  getAllAudit(obj: any) {
    return this.http.post(`${baseUrl}/getAllAudit`, obj);
  }

  getAudit(id : string, companyId:string, branchId:string) {
    return this.http.get<any[]>(`${baseUrl}/getAudit/${id}/${companyId}/${branchId}`);
  }
  insertAudit(data : any) {
    return this.http.post<any[]>(`${baseUrl}/insertAudit`, data);
  }
  updateAudit(id:string, data : any) {
    return this.http.put<any[]>(`${baseUrl}/updateAudit/${id}`, data);
  }
  deleteAudit(id:string, companyId:string) {
    return this.http.put<any[]>(`${baseUrl}/deleteAudit/${id}/${companyId}`, null);
  }
  ImportStockReportValidation(id : string, type : string, count : number, data : any, auditId : string) {
    return this.http.post<any[]>(`${baseUrl}/importStockReportValidation/${id}/${type}/${count}/${auditId}`, data);
  }
  completeAudit(data : any) {
    return this.http.put<any[]>(`${baseUrl}/completeAudit`, data);
  }
  getAuditSummary(id : string, companyId:string, branchId:string) {
    return this.http.get<any[]>(`${baseUrl}/getAuditSummary/${id}/${companyId}/${branchId}`);
  }
  updateAuditStatus(data : any) {
    return this.http.put<any[]>(`${baseUrl}/updateAuditStatus`, data);
  }
  getAuditroSummary(data : any) {
    return this.http.post(`${baseUrl}/GetAuditorSummary`, data);
  }
}
