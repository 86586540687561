<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed' : ''">
  <div class="logo-container">
    <button class="btn" (click)="toggleCollapse()" *ngIf="collapsed == false">
      <img src="assets/companylogos/white_logo.png" alt="..." />
    </button>
    <div class="logo-text pe-3" @fadeInOut *ngIf="collapsed">Stockount</div>
    <button
      class="btn closes"
      @rotate
      *ngIf="collapsed"
      (click)="closeSidenav()"
    >
      <i class="bi bi-x-lg"></i>
    </button>
  </div>
  <div class="scrollwrap scrollwrap_delayed">
    <ul
      class="sidenav-nav"
      (click)="toggleCollapse()"
      (keydown)="toggleCollapse()"
    >
      <ng-container *ngFor="let data of navData">
        <li class="sidenav-nav-item">
          <a
            class="sidenav-nav-link"
            (click)="handleClick($event, data)"
            *ngIf="data.items && data.items.length > 0"
            [ngClass]="getActiveClass(data)"
          >
            <img class="sidenav-link-icon" [src]="data.icon" alt="link-icon" />
            <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
              {{ data.label }}
            </span>
            <i
              *ngIf="data.items && collapsed"
              class="menu-collapse-icon"
              [ngClass]="
                !data.expanded ? 'bi bi-chevron-down' : 'bi bi-chevron-up'
              "
            ></i>
          </a>
          <a
            class="sidenav-nav-link"
            *ngIf="!data.items || (data.items && data.items.length === 0)"
            [routerLink]="[data.routeLink]"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="shrinkItems($event, data); closeSidenav()"
          >
            <img class="sidenav-link-icon" [src]="data.icon" alt="link-icon" />
            <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
              {{ data.label }}
            </span>
          </a>
          <div *ngIf="collapsed && data.items && data.items.length > 0">
            <app-sublevel-menu
              [data]="data"
              [collapsed]="collapsed"
              [multiple]="multiple"
              [expanded]="data.expanded"
            ></app-sublevel-menu>
          </div>
          <div
            *ngIf="
              !collapsed &&
              data.items &&
              data.items.length > 0 &&
              data.isPopoverOpen
            "
            class="after_collapse"
            appClickOutside
            (appClickOutside)="data.isPopoverOpen = false"
          >
            <div class="triangle"></div>
            <div class="list-group">
              <a
                (click)="handleClick($event, data)"
                *ngFor="let d of data.items"
                [routerLink]="d.routeLink"
                class="list-group-item list-group-item-action"
                aria-current="true"
              >
                {{ d.label }}
              </a>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
