<div class="companyPopup">
    <p-dialog 
        [closable]="false"
        [draggable]="false"
        [modal]="true"
        [visible]="parentData.showPopup" 
        [style]="{ width: '50rem', maxHeight: '80vh' }" 
        [styleClass]="'custom-dialog-header'"
        [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">

        <div class="p-4 dialog-content">
            <div class="row">
                <div class="col-md-10 col-12">
                    <div class=""><h6>Item does not exist, Add Now?</h6></div>
                </div>
                <div class="col-md-2 col-12 text-md-end">
                    <span (click)="close()" class="close">
                        <i class="bi bi-x-lg"></i>
                    </span>
                </div>
            </div>
            <div class="user_container">
                <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                    <div class="row mt-0 customDropdown">
                        <div class="col-12 col-sm-6 col-lg-4">
                          <label>Category</label><span class="text-danger">*</span><br />
                          <p-dropdown
                            formControlName="itemCategoryId"
                            placeholder="Choose Category"
                            id="role"
                            [options]="categoryList"
                            optionLabel="categoryName"
                            optionValue="itemCategoryId"
                          />
                          <label
                            *ngIf="IsSubmit && myForm.get('itemCategoryId')?.invalid"
                            class="text-danger"
                          >
                            Category is required.
                          </label>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4">
                          <label>Item Code</label><span class="text-danger">*</span><br />
                          <div class="d-flex align-items-center gap-3">
                            <input
                              #inputElement
                              type="text"
                              pInputText
                              formControlName="itemCode"
                              class="form-control"
                              [ngClass]="{
                                'p-inputtext': IsSubmit && myForm.get('itemCode')?.value == '',
                                'ng-dirty': IsSubmit && myForm.get('itemCode')?.value == '',
                                'ng-invalid': IsSubmit && myForm.get('itemCode')?.value == '',
                              }"
                            />
                          </div>
            
                          <label
                            *ngIf="IsSubmit && myForm.get('itemCode')?.invalid"
                            class="text-danger"
                          >
                            Item Code is required.
                          </label>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4">
                            <label>Item Name</label><span class="text-danger">*</span><br />
                            <input
                              #inputElement
                              type="text"
                              pInputText
                              formControlName="itemName"
                              class="form-control"
                              [ngClass]="{
                                'p-inputtext':
                                  IsSubmit && myForm.get('itemName')?.value == '',
                                'ng-dirty': IsSubmit && myForm.get('itemName')?.value == '',
                                'ng-invalid': IsSubmit && myForm.get('itemName')?.value == ''
                              }"
                            />
                            <label
                              *ngIf="IsSubmit && myForm.get('itemName')?.invalid"
                              class="text-danger"
                            >
                              Item Name is required.
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-4">
                            <label>Cost Price</label><br />
                            <input
                              #inputElement
                              type="number"
                              pInputText
                              formControlName="costPrice"
                              class="form-control"
                            />
                          </div>
                        <div class="col-12 col-sm-6 col-lg-4">
                            <label>Sell Price</label><br />
                            <input
                                #inputElement
                                type="number"
                                pInputText
                                formControlName="sellPrice"
                                class="form-control"
                            />
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4">
                            <label>Storage</label><br />
                            <div class="form-group autoComplete">
                            <p-autoComplete
                                formControlName="storage"
                                id="autoComplete"
                                [suggestions]="filterItemsList"
                                (completeMethod)="filterItems($event)"
                                [field]="fieldName"
                                (onSelect)="onItemSelect()"
                                #itemCodeAutoComplete
                            >
                            </p-autoComplete>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-4">
                            <label>UOM</label><br />
                            <input
                            #inputElement
                            type="text"
                            pInputText
                            formControlName="uom"
                            class="form-control"
                            />
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4">
                            <label>Ean Qr</label><br />
                            <input
                            #inputElement
                            type="text"
                            pInputText
                            formControlName="eanQr"
                            class="form-control"
                            />
                        </div>
            
                        <div class="col-12 col-sm-6 col-lg-4">
                            <button
                            type="submit"
                            class="btn btn-primary gridButton buttonColor"
                            >
                            <i class="bi bi-save"></i>&nbsp;&nbsp;&nbsp;Save</button
                            >&nbsp;&nbsp;
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </p-dialog>
</div>