<div class="header_container">
  <div class="left_side">
    <span (click)="loadPage(title)" class="title" *ngIf="title">
      <span>{{ title }}</span>
      <span *ngIf="auditName"> - {{ auditName }}</span>
    </span>
  </div>
  <img src="assets/sidemenuicons/Group.svg" alt="sidemenuicon" />
  <div class="right_side">
    <div class="custom-select-wrapper">
      <select
        class="form-select form-select-sm"
        (change)="branchChange($event)"
      >
        <option [value]="branch.branchId" *ngFor="let branch of branchList">
          {{ branch.branchName }}
        </option>
      </select>
    </div>

    <div class="prime-badge">
      <i
        class="bi bi-bell-fill icon-color-header"
        pBadge
        severity="danger"
        title="Notifications"
      ></i>
    </div>
    <div *ngIf="roleName == 'Admin'">
      <img
        src="assets/sidemenuicons/welcome_assist_icon.svg"
        class="welcome_icon"
        title="Welcome Assistant"
        (click)="welcome()"
        alt="welcome"
      />
    </div>
    <div class="dropdown">
      <i
        class="bi bi-person-fill icon-color-header"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      ></i>
      <ul class="dropdown-menu">
        <li>
          <button class="dropdown-item" type="button" (click)="editProfile()">
            <img
              class="logout-icon"
              src="assets/sidemenuicons/noun-edit.svg"
              alt="editProfile"
            />
            Edit Profile
          </button>
        </li>

        <li>
          <button
            class="dropdown-item"
            type="button"
            (click)="changePassword()"
          >
            <img
              class="logout-icon"
              src="assets/sidemenuicons/Changepassword_icon.svg"
              alt="change_password"
            />
            Change Password
          </button>
        </li>
        <li>
          <button class="dropdown-item" type="button" (click)="logout()">
            <img
              class="logout-icon"
              src="assets/sidemenuicons/logout.svg"
              alt="logout"
            />
            Logout
          </button>
        </li>
      </ul>
    </div>
    <!-- <div class="dropdown" (click)="popup()">
      <i class="bi bi-person-fill icon-color-header"></i>
    </div> -->
  </div>
</div>

<!-- <p-dialog
  header="User Profile"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '70vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <div>
    <div class="text-end changes">
      <button class="btn btn-outline-primary">
        <i class="bi bi-box-arrow-right"></i>&nbsp;&nbsp;&nbsp;Logout
      </button>
    </div>
    <div class="text-center">
      <i class="bi bi-person-circle" style="font-size: 80px"></i><br />
      <button class="btn">Select Picture</button>
    </div>
  </div>
</p-dialog> -->
