import { INavbarData } from './helper';

export const navbarData: INavbarData[] = [
  {
    routeLink: '/home',
    icon: 'assets/sidemenuicons/dashboard.svg',
    label: 'Dashboard',
    items: [],
    isPopoverOpen: false,
    permission: ['G_Dashboard'],
  },
  {
    routeLink: '/home/signupDashboard',
    icon: 'assets/sidemenuicons/signup_dashboard.svg',
    label: 'Signup Dashboard',
    items: [],
    isPopoverOpen: false,
    permission: ['S_Dashboard'],
  },
  {
    routeLink: '**',
    icon: 'assets/sidemenuicons/Item_icon.svg',
    label: 'Items',
    items: [
      {
        routeLink: '/home/items',
        icon: 'assets/sidemenuicons/Item_icon.svg',
        label: 'Items',
        isPopoverOpen: false,
        permission: ['I_Items'],
      },
      {
        routeLink: '/home/itemCategory',
        icon: 'assets/sidemenuicons/ItemCategory_icon.svg',
        label: 'Item Category',
        isPopoverOpen: false,
        permission: ['I_ItemsCategory'],
      },
      {
        routeLink: '/home/itemGroup',
        icon: 'assets/sidemenuicons/itemGroup.svg',
        label: 'Item Group',
        isPopoverOpen: false,
        permission: ['I_ItemGroup'],
      },
    ],
    permission: '**',
    isPopoverOpen: false,
  },
  {
    routeLink: '**',
    icon: 'assets/sidemenuicons/audit_icon.svg',
    label: 'Audits',
    items: [
      {
        routeLink: '/home/auditDashBoard',
        icon: 'assets/sidemenuicons/audit_plan_icon.svg',
        label: 'Dashboard',
        isPopoverOpen: false,
        permission: 'A_AuditDashBoard',
      },
      {
        routeLink: '/home/auditPlan',
        icon: 'assets/sidemenuicons/audit_plan_icon.svg',
        label: 'Audit Plans',
        isPopoverOpen: false,
        permission: 'AuditPlans',
      },
      {
        routeLink: '/home/audit',
        icon: 'assets/sidemenuicons/audit_icon.svg',
        label: 'Audits',
        isPopoverOpen: false,
        permission: [
          'A_OnGoingAudit',
          'A_UpComingAudit',
          'A_AuditHistory',
          'A_ImportStockReport',
          'A_CrossAudit',
          'A_AuditSummary',
          'A_AuditSetting',
        ],
      },
    ],
    permission: '**',
    isPopoverOpen: false,
  },
  {
    routeLink: '**',
    icon: 'assets/sidemenuicons/sales.svg',
    label: 'Sales',
    items: [
      {
        routeLink: '/home/salesInvoice',
        icon: 'assets/sidemenuicons/sales.svg',
        label: 'Sales',
        isPopoverOpen: false,
        permission: 'S_SalesInvoice',
      },
      {
        routeLink: '/home/salesInvoice/salesreport',
        icon: 'assets/sidemenuicons/audit_plan_icon.svg',
        label: 'Sales Report',
        isPopoverOpen: false,
        permission: 'S_SalesReports',
      },
    ],
    isPopoverOpen: false,
    permission: '**',
  },
  {
    routeLink: '**',
    icon: 'assets/sidemenuicons/purchase.svg',
    label: 'Purchases',
    items: [
      {
        routeLink: '/home/purchaseDashboard',
        icon: 'assets/sidemenuicons/purchase.svg',
        label: 'Purchase Dashboard',
        isPopoverOpen: false,
        permission: 'P_PurchaseDashBoard',
      },
      {
        routeLink: '/home/purchase',
        icon: 'assets/sidemenuicons/purchase.svg',
        label: 'Purchases',
        isPopoverOpen: false,
        permission: 'P_PurchaseInvoice',
      },
      {
        routeLink: '/home/purchase/purchasereport',
        icon: 'assets/sidemenuicons/audit_plan_icon.svg',
        label: 'Purchase Report',
        isPopoverOpen: false,
        permission: 'P_PurchaseReports',
      },
    ],
    isPopoverOpen: false,
    permission: '**',
  },

  {
    routeLink: '/home/customerManagement',
    icon: 'assets/sidemenuicons/customer.svg',
    label: 'Customer',
    items: [],
    isPopoverOpen: false,
    permission: ['G_CustomerManagement'],
  },
  {
    routeLink: '/home/vendorManagement',
    icon: 'assets/sidemenuicons/vendor.svg',
    label: 'Vendor',
    items: [],
    isPopoverOpen: false,
    permission: ['G_VendorManagement'],
  },
  {
    routeLink: '/home/company/companylist',
    icon: 'assets/sidemenuicons/company_icon.svg',
    label: 'Companies',
    items: [],
    isPopoverOpen: false,
    permission: ['SP_Companies'],
  },
  {
    routeLink: '/home/appadmin',
    icon: 'assets/sidemenuicons/appadmin.png',
    label: 'App Admin',
    items: [
      {
        routeLink: '/home/appadmin/plan',
        icon: 'assets/sidemenuicons/plan.png',
        label: 'Plan',
        isPopoverOpen: false,
        permission: ['SP_Plans'],
      },
      {
        routeLink: '/home/appadmin/subscription',
        icon: 'assets/sidemenuicons/subscription.png',
        label: 'Subscription',
        isPopoverOpen: false,
        permission: ['SP_Subscriptions'],
      },
    ],
    isPopoverOpen: false,
    permission: ['SP_Plans','SP_Subscriptions'],
  },
  {
    routeLink: '/home/reports',
    icon: 'assets/sidemenuicons/Reports.svg',
    label: 'Reports',
    items: [],
    isPopoverOpen: false,
    permission: ['G_VendorManagement'],
  },
  {
    routeLink: '**',
    icon: 'assets/sidemenuicons/settings.svg',
    label: 'Settings',
    items: [
      {
        routeLink: '/home/company',
        icon: 'assets/sidemenuicons/company_icon.svg',
        label: 'Company',
        isPopoverOpen: false,
        permission: ['G_Company'],
      },
      {
        routeLink: '/home/branch',
        icon: 'assets/sidemenuicons/branch_icon.svg',
        label: 'Branch',
        isPopoverOpen: false,
        permission: ['G_Branch'],
      },
      {
        routeLink: '/home/labelConfig',
        icon: 'assets/sidemenuicons/label-config.svg',
        label: 'Label Config',
        isPopoverOpen: false,
        permission: ['G_LabelConfiguration'],
      },
      {
        routeLink: '/home/rolesManagement/roles',
        icon: 'assets/sidemenuicons/Group 1000005076.svg',
        label: 'Role',
        isPopoverOpen: false,
        permission: ['G_Role'],
      },
      {
        routeLink: '/home/rolesManagement/rolesAccess',
        icon: 'assets/sidemenuicons/Group 1000005074.svg',
        label: 'Role Access',
        isPopoverOpen: false,
        permission: ['G_RoleAccess'],
      },
      {
        routeLink: '/home/user',
        icon: 'assets/sidemenuicons/user_icon.svg',
        label: 'User',
        isPopoverOpen: false,
        permission: ['G_User'],
      },
      {
        routeLink: '/home/storage',
        icon: 'assets/sidemenuicons/database-solid.svg',
        label: 'Storage',
        isPopoverOpen: false,
        permission: ['G_Storage', 'G_Aisle', 'G_Bay'],
      },
      {
        routeLink: '/home/tax',
        icon: 'assets/sidemenuicons/tax.svg',
        label: 'Tax',
        isPopoverOpen: false,
        permission: ['I_ItemGroup'],
      },
      {
        routeLink: '/home/autoNumbering',
        icon: 'assets/sidemenuicons/autonumber.svg',
        label: 'Auto Numbering',
        isPopoverOpen: false,
        permission: ['G_AutoNumbering'],
      },
    ],
    isPopoverOpen: false,
    permission: '**',
  },
];
