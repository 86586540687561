import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoaderService } from '../loader/loader-service.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  localData: any;
  constructor(
    private loaderService: LoaderService,
    private auth: AuthorizationService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  showError(data: any) {
    this.toastr.error(`${data}`, '', {
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
    });
    if (data == 'Session Timeout') {
      this.auth.logout();
    }
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.loaderService.show();

    this.localData = localStorage.getItem('credentials');
    this.localData = JSON.parse(this.localData);
    if (this.localData && this.localData.token) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${this.localData.token}` },
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('HTTP Error:', error);
        if (error.status == 0) {
          this.showError('Session Timeout');
          // } else if (this.router.url != '/home/changepassword' && this.router.url != '/home/storage') {
          //   this.showError('An Error Occured');
        } else if (error.status == 400) {
          this.showError(error.error.message);
        } else if (error.status == 500) {
          if (error.error && error.error.message && error.error.message != '')
            this.showError(error.error.message);
          else if (error.error != null && error.error != '')
            this.showError(error.error);
        } else if (
          error.error.message == 'Token Expired!' &&
          error.status == 401
        ) {
          localStorage.clear();
          this.router.navigate(['/authorization/login']);
        }
        return throwError(() => error);
      }),
      finalize(() => this.loaderService.hide())
    );
  }
}
