import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuditStatusService {
  constructor() {}

  getPrimaryStatusName(status: string): string {
    switch (status) {
      case PrimaryStatus.NotStarted:
        return 'Not Started';
      case PrimaryStatus.InProgress:
        return 'In Progress';
      case PrimaryStatus.StockSheetUpdated:
        return 'Stock Sheet Updated';
      case PrimaryStatus.Completed:
        return 'Completed';
      case PrimaryStatus.AutoCompleted:
        return 'Auto Completed';
      case PrimaryStatus.Expired:
        return 'Expired';
      default:
        return '';
    }
  }

  getScreenAccess(data: any, screen: string) {
    let IsAccess = true;
    let test = data.find(
      (resp: { accessCodeName: any }) => resp.accessCodeName == screen
    );
    if (test) IsAccess = false;
    return IsAccess;
  }
}

export enum PrimaryStatus {
  NotStarted = 'N',
  StockSheetUpdated = 'S',
  InProgress = 'I',
  Completed = 'C',
  AutoCompleted = 'A',
  Expired = 'E',
}

export enum BarcodeStatus {
  Ean = 'Barcode - EAN13',
  Code = 'Barcode - Code128',
  QR = 'QR',
}
export enum MenuName {
  audit = 'Audits',
  item = 'Items',
  itemCategory = 'Item Category',
  auditPlan = 'Audit Plan',
  company = 'Company',
  user = 'User',
  branch = 'Branch',
  storage = 'Storage',
  labelConfig = 'Label Config',
  itemGroup = 'Item Group',
  mediaStorageConfig = 'Media Storage Config',
  role = 'Roles',
  autoNumbering = 'Auto Numbering',
  customer = 'Customer',
  vendor = 'Vendor',
  salesInvoice = 'Sales Invoice',
  auditDashboard = 'Audit Dashboard',
}
export enum PerpetualTypeName {
  Random = 'Random',
  Category = 'ByCategory',
  Storage = 'ByStorage',
}
export enum CrossAuditType {
  Random = 'Random',
  Discrepancy = 'Discrepancy',
}
export enum AuditPlanStatus {
  Manual = 'Manual',
  Run = 'Running',
  Suspend = 'Suspend',
}
export enum PeriodicalTyeName{
  Random = 'Random',
  Category = 'ByCategory',
  Storage = 'ByStorage',
}
