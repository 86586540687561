<div class="card">
    <div class="card-body">
        <p-table [value]="auditorSummaryList" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '20rem' }">
            <ng-template pTemplate="header">
                <tr>
                    <th>Auditor</th>
                    <th>Assigned Inventory</th>
                    <th>Completed Inventory</th>
                    <!-- <th>Pending Inventory</th> -->
                    <th>Progress</th>
                    <th>Elapsed Time</th>
                    <th>Status</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-summary>
                <tr>
                    <td>{{ summary.auditorName }}</td>
                    <td class="centered">{{ summary.assignedInventoryCount }}</td>
                    <td class="centered">{{ summary.auditedCount }}</td>
                    <!-- <td class="centered">{{ summary.remainingCount }}</td> -->
                    <td>
                        <p-progressBar 
                            [value]="(summary.auditedCount / summary.assignedInventoryCount) * 100" 
                            [showValue]="false" 
                            [style]="{ 'width': '100%', 'top': '10px' }">
                        </p-progressBar>
                        <div style="position: relative; top: -12px; text-align: center; font-weight: bold;">
                            {{ (summary.assignedInventoryCount > 0 ? (summary.auditedCount / summary.assignedInventoryCount * 100) : 0) | number : '1.0-0' }} %
                        </div>
                    </td>                    
                    <td class="centered">{{ summary.timer }}</td>
                    <!-- <td>
                        {{ summary.remainingCount === 0 
                            ? (summary.duration || 'N/A')
                            : (summary.startTime | date:'short') }}
                    </td> -->
                    <td>{{ summary.status }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>