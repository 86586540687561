import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const baseUrl = `${environment.apiUrl}/auditCrossCheck`;

@Injectable({
  providedIn: 'root'
})
export class CrossAuditService {

  constructor(private http: HttpClient) { }

  getCrossAuditDetails(auditId : string, companyId : string, branchId : string, auditPlanId : string) {
    return this.http.get<any[]>(`${baseUrl}/getCrossAuditDetails?AuditId=${auditId}&CompanyId=${companyId}&BranchId=${branchId}&AuditPlanId=${auditPlanId}`);
  }
  saveCrossAudit(data : any) {
    return this.http.post<any[]>(`${baseUrl}/saveCrossAudit`, data);
  }
  updateCrossCheckStatus(data : any) {
    return this.http.put<any[]>(`${baseUrl}/updateCrossCheckStatus`, data);
  }
  
}
