import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, Observable, switchMap } from 'rxjs';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { MenuName } from 'src/app/services/audit-status.service';
import { HeadernameService } from 'src/app/services/headername.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  isSideNavCollapsed = false;
  screenWidth = 0;
  localData: any;
  branchList: any;
  title: string = '';
  auditName: any;
  roleName: any;
  visible = true;
  constructor(
    private auth: AuthorizationService,
    private router: Router,
    private headernameService: HeadernameService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const data: any = localStorage.getItem('credentials');
    this.localData = JSON.parse(data);
    this.branchList = this.localData.branches;
    if (this.branchList.length > 0) {
      localStorage.setItem('branchId', this.branchList[0].branchId);
    }
    this.roleName = this.localData.roleName;
    // Subscribe to changes in route data
    // Subscribe to router events and get route data
    // this.router.events
    //   .pipe(
    //     filter((event) => event instanceof NavigationEnd),
    //     switchMap(() => this.getTitle())
    //   )
    //   .subscribe((title) => {
    //     this.title = title;
    //     console.log('this.title', this.title);
    //   });
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route: ActivatedRoute = this.router.routerState.root;
        let routeTitle = '';
        while (route!.firstChild) {
          route = route.firstChild;
        }
        if (route.snapshot.data['title']) {
          routeTitle = route!.snapshot.data['title'];
        }
        return routeTitle;
      })
    )
    .subscribe((title: string) => {
      if (title) {
        this.title = title;
        // this.titleService.setTitle(`My App - ${title}`);
      }
    });
    // Initial load
    this.getTitle().subscribe((title) => {
      this.title = title;
      console.log('this.title --', this.title);
    });

    this.headernameService.headerName$.subscribe((name) => {
      this.auditName = name;
      console.log('New header name:', name);
    });
  }
  private getTitle(): Observable<string> {
    const childRoute = this.route.firstChild;
    if (childRoute) {
      return childRoute.data.pipe(map((data) => data['title'] || ''));
    } else {
      return this.route.data.pipe(map((data) => data['title'] || ''));
    }
  }

  onToggleSideNav(data: any): void {
    this.screenWidth = data.screenWidth;
    this.isSideNavCollapsed = data.collapsed;
  }

  changePassword() {
    this.router.navigate(['home/changepassword']);
  }
  branchChange(e: any) {
    console.log(e.target.value);
    localStorage.setItem('branchId', e.target.value);
  }
  logout() {
    this.auth.logout();
  }
  loadPage(title: any) {
    if (title == MenuName.audit) this.router.navigate([`home/audit`]);
    else if (title == MenuName.item) this.router.navigate([`home/items`]);
    else if (title == MenuName.itemCategory)
      this.router.navigate([`home/itemCategory`]);
    else if (title == MenuName.auditDashboard)
      this.router.navigate([`home/auditDashBoard`]);
    else if (title == MenuName.auditPlan)
      this.router.navigate([`home/auditPlan`]);
    else if (title == MenuName.company) this.router.navigate([`home/company`]);
    else if (title == MenuName.user) this.router.navigate([`home/user`]);
    else if (title == MenuName.branch) this.router.navigate([`home/branch`]);
    else if (title == MenuName.storage) this.router.navigate([`home/storage`]);
    else if (title == MenuName.labelConfig)
      this.router.navigate([`home/labelConfig`]);
    else if (title == MenuName.itemGroup)
      this.router.navigate([`home/itemGroup`]);
    else if (title == MenuName.mediaStorageConfig)
      this.router.navigate([`home/mediaStorageConfig`]);
    else if (title == MenuName.role)
      this.router.navigate([`home/rolesManagement/roles`]);
    else if (title == MenuName.autoNumbering)
      this.router.navigate([`home/autoNumbering`]);
    else if (title == MenuName.customer)
      this.router.navigate([`home/customerManagement`]);
    else if (title == MenuName.vendor)
      this.router.navigate([`home/vendorManagement`]);
    else if (title == MenuName.salesInvoice)
      this.router.navigate([`home/salesInvoice`]);
  }

  welcome() {
    this.router.navigate(['/welcomeAssistance']);
  }
  popup() {
    this.visible = true;
  }

  editProfile() {
    this.router.navigate(['/home/user/userAdd']);
    localStorage.setItem('editUser', 'true');
  }
}
