<div class="user_container p-3" [style.max-height]="maxContainerHeight">
    <div class="row tabView">
        <p-tabView
          [(activeIndex)]="activeIndex"
          (onChange)="onTabChange($event.index)"
        >
          <!-- <p-tabPanel [disabled]="IsStockReportDisabled || IsSRAccess" header="Import Stock Report">
          </p-tabPanel> -->
            <p-tabPanel header="Barcode Scan">
            </p-tabPanel>
            <p-tabPanel header="Camera Scan">
            </p-tabPanel>
            <p-tabPanel header="Manual">
            </p-tabPanel>
        </p-tabView>
    </div>
    
    <div *ngIf="scanType == 'CameraScan'">
        <div class="row p-3" >
            <div class="col-md-12 col-12 scanner-container">
                <app-barcode-scan [parentData]="screenName" [triggerFunction]="trigger" (childButtonClick)="handleChildButtonClick($event)"></app-barcode-scan>
            </div>
            
        </div>
        <div class="row scan">
            <div class="col-sm-4"></div>
            <div class="col-sm-4">
                <button type="button" class="btn btn-primary buttonColor btn-lg" (click)="scan()">
                    <i class="bi bi-qr-code-scan"></i>&nbsp;&nbsp;&nbsp;Scan
                </button>
            </div>
            <div class="col-sm-4">
                <label ngClass="errMsg" *ngIf="IsScan && (barcodes == '' || barcodes == null || !barcodes)" class="text-danger">Barcode is empty.</label>
            </div>
        </div>
    </div>

    <div *ngIf="addItemObj.showPopup && routeSegment == 'stockAudit'">
        <app-add-item-popup [parentData]="addItemObj" (childButtonClick)="childButtonFromItem($event)"></app-add-item-popup>
    </div>
      
    <form [formGroup]="myForm">
        <!-- <div class="" *ngIf="countdown > 0 && scanType == 'BarcodeScan'">
            <div class="countdown">
                <h1>{{ countdown }}</h1>
            </div>
        </div> -->
        <div class="row">
            <div class="col-md-12 col-12">
                <label class="Progress">Progress</label>
            </div>
            <div class="col-md-12 col-12 mb-2">
                <p-progressBar *ngIf="routeSegment == 'stockAudit'" [value]="auditObj ? auditObj.qtyPercentage : 0"></p-progressBar>
                <p-progressBar *ngIf="routeSegment == 'crossAudit'" [value]="overallProgress"></p-progressBar>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 autoComplete">
                <label class="current">Storage - Aisle - Bay</label><br />
                <p-autoComplete
                    formControlName="storageObject"
                    id="autoComplete"
                    [suggestions]="storageItems"
                    (completeMethod)="loadFilterItems($event)"
                    [field]="fieldName"
                    (onSelect)="onItemSelectStorage()"
                    id="storage"
                >
                </p-autoComplete>
            </div>
            <div class="col-md-6"></div>
        </div>
        <div class="row">
            <label for="itemCode">Item Code / Name / Ean<span class="text-danger">*</span></label>
            <div class="form-group autoComplete" >
                <p-autoComplete formControlName="itemCode" id="autoComplete" [suggestions]="filterItemsList" (input)="loadItemCode()"
                    (completeMethod)="filterItems($event, 'type')" field="filterValue" (onSelect)="onItemSelect()" #itemCodeAutoComplete>
                </p-autoComplete>
            </div>
        </div>
        <div *ngIf="IsSerialized">
            <div class="row" *ngIf="auditObj.id1Name">
                <div class="form-group">
                    <label for="">{{auditObj.id1Name}}</label><span class="text-danger">*</span>
                    <input type="text" class="form-control" formControlName="id1Name" />
                </div>
            </div>
            <div class="row" *ngIf="auditObj.id2Name">
                <div class="form-group">
                    <label for="">{{auditObj.id2Name}}</label><span class="text-danger">*</span>
                    <input type="text" class="form-control" formControlName="id2Name" />
                </div>
            </div>
            <div class="row" *ngIf="auditObj.id3Name">
                <div class="form-group">
                    <label for="">{{auditObj.id3Name}}</label><span class="text-danger">*</span>
                    <input type="text" class="form-control" formControlName="id3Name" />
                </div>
            </div>
        </div>
        <div *ngIf="IsBatch">
            <div class="row">
                <div class="form-group">
                    <label for="">Batch No</label><span class="text-danger">*</span>
                    <input type="text" class="form-control" formControlName="batchNo" />
                </div>
            </div>
        </div>
        
        <div class="d-flex flex-nowrap mt-4" *ngIf="IsUnit || IsBatch">
            <div class="order-1 me-3">
                <label for="auditedQty">
                    <span *ngIf="routeSegment == 'stockAudit'">Audited Qty</span>
                    <span *ngIf="routeSegment == 'crossAudit'">Cross Audit Qty</span>
                </label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend" (click)="addQty('audited', 'minus');">
                        <span class="input-group-text" id="basic-addon1">
                            <i class="bi bi-dash-square-fill"></i>
                        </span>
                    </div>
                    <input type="number" class="form-control" id="auditedQty" formControlName="auditedQty" autocomplete="off" />
                    <div class="input-group-append" (click)="addQty('audited', 'plus');">
                        <span class="input-group-text" id="basic-addon1">
                            <i class="bi bi-plus-square-fill"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="order-2" *ngIf="IsDamageQty">
                <label for="damageQty">Damage Qty</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend" (click)="addQty('damage', 'minus');">
                        <span class="input-group-text" id="basic-addon1">
                            <i class="bi bi-dash-square-fill"></i>
                        </span>
                    </div>
                    <input type="number" class="form-control" id="damageQty" formControlName="damagedQty" autocomplete="off" />
                    <div class="input-group-append" (click)="addQty('damage', 'plus');">
                        <span class="input-group-text" id="basic-addon1">
                            <i class="bi bi-plus-square-fill"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="form-group">
                <label for="damageQty">Location</label>
                <span (click)="updateLocation()" class="blink tooltip-container" *ngIf="IsBlink && myForm.get('storageObject')?.value && (myForm.get('storageObject')?.value != myForm.get('auditStockLocation')?.value)">
                    Update Location
                    <span class="tooltip-text">The Items Location in Database seems to be different from the Audit Location. Would you like to update the Items Database?</span>
                </span>
                <input type="text" pInputText formControlName="auditStockLocation" />
            </div>
        </div>
        <div class="row add mb-5">
            <div class="col-6">
                <button *ngIf="scanType == 'BarcodeScan' && !IsStop && countdown != 0" type="button" class="btn btn-primary buttonColor btn-lg" (click)="continue()">
                    <i class="bi bi-play-btn"></i>&nbsp;&nbsp;&nbsp;Continue
                </button>
                <button *ngIf="scanType == 'BarcodeScan' && IsStop" type="button" class="btn btn-primary buttonColor btn-lg" (click)="stop()">
                    <i class="bi bi-plus-lg"></i>&nbsp;&nbsp;&nbsp;Auto Add <span *ngIf="countdown > 0">({{countdown}})</span>
                </button>
                <button type="button" *ngIf="scanType == 'Manual'" class="btn btn-primary buttonColor btn-lg" (click)="add()">
                    <i class="bi bi-plus-lg"></i>&nbsp;&nbsp;&nbsp;Add Item
                </button>
                <button *ngIf="scanType == 'CameraScan'" type="submit" class="btn btn-primary buttonColor btn-lg" (click)="add()">
                    <i class="bi bi-plus-lg"></i>&nbsp;&nbsp;&nbsp;Add
                </button>
            </div>
            <div class="col-6">
                <button type="button" (click)="clearData()" class="btn btn-warning btn-lg">
                    <i class="bi bi-x-lg"></i>&nbsp;Clear
                </button>
            </div>
        </div>
        <div class="mb-5"></div>
        

    </form>
    <div *ngIf="routeSegment == 'stockAudit'">
        <p-divider></p-divider>
        <app-audit-stock-list [parentData]="auditStockListObj" (childButtonClick)="childButtonFromList($event)"></app-audit-stock-list>
    </div>
    
    <audio #barcodeSuccess src="../../../../../assets/audio/barcode_success.mp3"></audio>
    <audio #barcodeError src="../../../../../assets/audio/barcode_error.mp3"></audio>
    <app-spinner></app-spinner>
    <p-confirmDialog
    [style]="{ width: '35vw' }"
    [baseZIndex]="10000"
    ></p-confirmDialog>
</div>