import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HidScannerService {
  private inputBuffer: string = '';
  private scanSubject = new Subject<string>();

  constructor() {
    window.addEventListener('keypress', this.handleKeypress.bind(this));
  }

  private handleKeypress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.scanSubject.next(this.inputBuffer);
      this.inputBuffer = '';
    } else {
      this.inputBuffer += event.key;
    }
  }

  getScans() {
    return this.scanSubject.asObservable();
  }
}
